import React from 'react';
import PropTypes from 'prop-types';

//material ui
import Book from '@material-ui/icons/Book';
import Build from '@material-ui/icons/Build';
import Done from '@material-ui/icons/Done';

//images
import check from '../../images/check.png';
import cross from '../../images/cross.png';

const RentalFacts = props => {
  const { unitDetails } = props;
  const styles = {
    image: {
      width: '12px',
    },
  };
  return (
    <div className="features">
      <div className="col-container-features">
        <div className="col-table-cell-features">
          <div className="detailsHeader">
            <Book />
            <p>
              <strong>Rental Facts</strong>
            </p>
          </div>
          <ul className="listOfFeatures">
            <li>
              Pets{' '}
              <img
                alt="..."
                src={unitDetails.pets__c ? check : cross}
                style={styles.image}
              />
            </li>
            <li>
              Credit Check{' '}
              <img
                src={unitDetails.credit_check__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Background Check{' '}
              <img
                src={unitDetails.background_check__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              No Evictions{' '}
              <img
                src={unitDetails.no_evictions__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              55+{' '}
              <img
                src={unitDetails.x55_plus__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              62+{' '}
              <img
                src={unitDetails.x62_plus__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Parking{' '}
              <img
                src={unitDetails.parking__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Wheelchair Accessible{' '}
              <img
                src={unitDetails.wheelchair_accessible__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Security Deposit:{' '}
              {unitDetails.security_deposit_amount__c
                ? ' $' + unitDetails.security_deposit_amount__c
                : 'N/A'}
            </li>
            {unitDetails.holding_fee__c !== false && (
              <li>
                Holding Fee:{' $' + unitDetails.holding_fee_amount__c && ' Yes'}
              </li>
            )}
            {unitDetails.holding_fee__c !== false && (
              <li>
                Holding Fee Date Range:
                {' ' +
                  unitDetails.holding_fee_start_date__c +
                  ' to ' +
                  unitDetails.holding_fee_end_date__c}
              </li>
            )}
          </ul>
        </div>
        <div className="col-table-cell-features">
          <div className="detailsHeader">
            <Build />
            <p>
              <strong>Appliances</strong>
            </p>
          </div>
          <ul className="listOfFeatures">
            <li>
              Stove/Range/Oven{' '}
              <img
                src={unitDetails.stove_range_oven__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Refrigerator{' '}
              <img
                src={unitDetails.refrigerator__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Microwave{' '}
              <img
                src={unitDetails.microwave__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Dishwasher{' '}
              <img
                src={unitDetails.dishwasher__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Washer/Dryer{' '}
              <img
                src={unitDetails.washer__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Garbage Disposal{' '}
              <img
                src={unitDetails.garbage_disposal__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
          </ul>
        </div>
        <div className="col-table-cell-features">
          <div className="detailsHeader">
            <Done />
            <p>
              <strong>Landlord Paid Utilities</strong>
            </p>
          </div>
          <ul className="listOfFeatures">
            <li>
              Gas{' '}
              <img
                src={unitDetails.gas__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Water/Sewer{' '}
              <img
                src={unitDetails.water_sewer__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Trash{' '}
              <img
                src={unitDetails.trash__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Electricity{' '}
              <img
                src={unitDetails.electricity__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Heat-Electric{' '}
              <img
                src={unitDetails.heat_electric__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Heat-Gas{' '}
              <img
                src={unitDetails.heat_gas__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
            <li>
              Common Area Charge{' '}
              <img
                src={unitDetails.common_area_charge__c ? check : cross}
                style={styles.image}
                alt="..."
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

RentalFacts.propTypes = {
  unitDetails: PropTypes.object,
};

export default RentalFacts;
