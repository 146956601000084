import React from 'react';
import StickyBox from 'react-sticky-box';
import DayPicker from 'react-day-picker';
import Button from '@material-ui/core/Button';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import axios from 'axios';
import { List, ListItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import { auth } from 'path-firebase/firebase';
import { findApiUrl } from 'config/env.js';
import { isSameDay, format, parseISO } from 'date-fns';
import { useOktaAuth } from '@okta/okta-react';

import 'react-day-picker/lib/style.css';

//components
import MessageForm from './MessageForm';
import Modal from 'Components/Matching/Modal';

// long transform to USD without fraction
const transformToUSD = num => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};

const formatDate = options => dateTime => format(parseISO(dateTime), options);

const mkDayHour = formatDate('eee, h:mma');

const mkHour = formatDate('h:mma');

const ContactInfo = props => {
  const { unitDetails, email, criteriaAnswers = {} } = props;
  let unit_title = unitDetails.name;
  let accountOwnerName = unitDetails.unit_contact_name__c?.split(' ')[0];
  let accountOwnerEmail = unitDetails.unit_contact_email__c;
  let phoneNumber = unitDetails.unit_contact_phone__c;
  let unit_contact_id = unitDetails.unit_id__c;
  let emailAddress =
    `mailto:${unitDetails.unit_contact_email__c}` +
    `?subject=Inquiry For ${unitDetails.name}&body=Hi ${
      unitDetails.unit_contact_name__c?.split(' ')[0]
    },`;
  let phoneCall = `tel:${unitDetails.unit_contact_phone__c}`;
  const [slots, setSlots] = React.useState([]);
  const [daySlots, setDaySlots] = React.useState([]);
  const [openScheduling, setOpenScheduling] = React.useState(false);
  const [selectedSlot, setSelectedSlot] = React.useState(null);
  const [selectedDay, setSelectedDay] = React.useState(null);

  React.useEffect(() => {
    axios.interceptors.request.use(function (config) {
      config.headers['x-jwt'] = authState.accessToken.accessToken;
      return config;
    });
    axios
      .get(`${findApiUrl()}/get-available-slots/${unitDetails.id}`)
      .then(({ data }) => {
        setSlots(
          data.records.filter(
            ({ status__c: status }) => status == 'Available'
          ) || []
        );
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const modifiers = {
    highlighted: slots.map(s => parseISO(s.start_date_time__c)),
    selected: selectedDay,
  };

  const handleDayClick = day => {
    setSelectedSlot(null);
    setSelectedDay(day);
    const foundSlots = slots.filter(s =>
      isSameDay(day, parseISO(s.start_date_time__c))
    );
    setDaySlots(foundSlots);
  };

  const handleMonthChange = () => {
    setSelectedSlot(null);
    setSelectedDay(null);
    setDaySlots([]);
  };
  const { oktaAuth, authState } = useOktaAuth();

  const handleSubmit = () => {
    if (!selectedSlot) {
      return;
    }
    axios.interceptors.request.use(function (config) {
      config.headers['x-jwt'] = authState.accessToken.accessToken;
      return config;
    });
    setSelectedSlot(null);
    setSelectedDay(null);
    setDaySlots([]);
    setOpenScheduling(false);
    return axios
      .post(`${findApiUrl()}/reserve-available-slot`, {
        address: unitDetails.billingaddress,
        id: selectedSlot.id,
        housingSpecialistName: unitDetails.unit_contact_name__c,
        name: criteriaAnswers.name,
        unitId: unitDetails.id,
        unitName: unit_title,
        userId: authState.accessToken.claims.uid,
        email: authState.accesstoken.claims.sub,
      })
      .then(({ data }) => toast.success('Successfully scheduled appointment!'))
      .catch(err => {
        toast.error(
          'There was an error, please contact support if the issue continues!'
        );
        console.error({ err });
      });
  };

  return (
    <StickyBox className="content-sidebar" offsetTop={10}>
      <Modal
        open={openScheduling}
        title="Schedule an appointment"
        btnTwo="Submit"
        btnOne="Cancel"
        onCommit={handleSubmit}
        commitEnabled={Boolean(selectedSlot)}
        setOpen={setOpenScheduling}>
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          <DayPicker
            modifiers={modifiers}
            modifiersStyles={{
              today: { color: 'rgb(137, 040, 144)' },
            }}
            onDayClick={handleDayClick}
            onMonthChange={handleMonthChange}
          />
          {daySlots.length ? (
            <List classes={{ root: 'housing-specialist-time-picker' }}>
              {daySlots.map(slot => {
                return (
                  <ListItem>
                    <Button
                      color="secondary"
                      variant="outlined"
                      style={{
                        backgroundColor:
                          selectedSlot &&
                          selectedSlot.start_date_time__c ==
                            slot.start_date_time__c
                            ? 'lightblue'
                            : 'inherit',
                      }}
                      onClick={() => setSelectedSlot(slot)}>
                      {mkDayHour(slot.start_date_time__c)} -{' '}
                      {mkHour(slot.end_date_time__c)}
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          ) : selectedDay && daySlots.length == 0 ? (
            <p>No available slots</p>
          ) : (
            <p>Select a day to view available slots</p>
          )}
        </div>
      </Modal>

      <div className="contactInfo contact-info-style">
        <div className="rent">
          <p>Rent Per Month</p>
          <h3>{transformToUSD(unitDetails.account_rent__c)}</h3>
        </div>
        <MessageForm
          unit_title={unit_title}
          unit_id={unit_contact_id}
          senderEmail={email}
          recipient={accountOwnerEmail}
          accountOwnerName={accountOwnerName}
        />
        <div className="contact-btn-wrapper phoneCall">
          <div className="row-one">
            <Button
              className="contactButton"
              color="primary"
              style={{
                fullWidth: true,
                color: '#2BB0E9',
                fontFamily: 'Open Sans, sans-serif',
              }}>
              <Email />
              <a href={emailAddress}>
                {accountOwnerName + ' @ ' + accountOwnerEmail}
              </a>
            </Button>
            <Button
              className="contactButton"
              color="primary"
              style={{ fullWidth: true, fontFamily: 'Open Sans, sans-serif' }}>
              <Phone />
              <a href={phoneCall}>{phoneNumber}</a>
            </Button>
            {/* <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                // Reset selected slot if opening again
                setSelectedSlot(null);
                setSelectedDay(null);
                setOpenScheduling(true);
              }}>
              <CalendarTodayIcon />
              Schedule a viewing
            </Button> */}
          </div>
        </div>
      </div>
    </StickyBox>
  );
};

export default ContactInfo;
