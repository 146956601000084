import React from 'react';
import { auth } from 'path-firebase/firebase';
import { findApiUrl } from 'config/env';
import axios from 'axios';
import {
  ReactiveComponent,
  RangeInput,
  StateProvider,
} from '@appbaseio/reactivesearch';
import { spaData, bedData } from '../Matching/constants';
import Location from '../Matching/Location';
import {
  Button,
  FormControlLabel,
  Grow,
  Paper,
  Popover,
  Slide,
  Switch,
} from '@material-ui/core';
import Modal from 'Components/Matching/Modal';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { clearValues } from '@appbaseio/reactivecore/lib/actions';
import { connect } from '@appbaseio/reactivesearch/lib/utils';
import { Criteria } from 'Components/Criteria';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useOktaAuth } from '@okta/okta-react';

import styles from './styles.module.scss';

const formatRangeLabel = selectedValue =>
  `$${selectedValue[0]} – $${selectedValue[1]}`.replace(/000/g, 'k');

const LocationSelectComponent = ({ setQuery, selectedValue }) => {
  return (
    <Location
      selectedValue={selectedValue}
      spaData={spaData}
      setQuery={setQuery}
    />
  );
};

const RentalPriceSelectComponent = ({
  setQuery,
  selectedValue,
  savedQuery,
}) => {
  return (
    <div className={styles['range-input-wrapper']}>
      <RangeInput
        className={styles['range-input']}
        componentId="RentalPrice"
        dataField="account_rent__c"
        range={{
          start: 0,
          end: 5000,
        }}
        rangeLabels={{
          start: '$0',
          end: '$5k',
        }}
        defaultValue={{
          start: savedQuery.search.params.RentalPrice?.[0] || 0,
          end: savedQuery.search.params.RentalPrice?.[1] || 5000,
        }}
        stepValue={100}
        tooltipTrigger="always"
        showHistogram={true}
      />
    </div>
  );
};

const BedSelectComponent = ({ mkQuery, setQuery, selectedValue }) => {
  React.useEffect(() => {
    // FIXME: catch if array -- data should not be array
    const val =
      typeof selectedValue == 'string' ? selectedValue : selectedValue?.[0];
    setQuery(mkQuery(val));
  }, []);

  const toggleSelectedBed = key => () =>
    key == selectedValue ? setQuery(mkQuery(null)) : setQuery(mkQuery(key));
  return (
    <div className={styles['filter-button-wrapper']}>
      {Object.keys(bedData).map(key => (
        <Button
          key={key}
          variant={key == selectedValue ? 'contained' : 'outlined'}
          onClick={toggleSelectedBed(key)}>
          <label>{bedData[key]}</label>
        </Button>
      ))}
    </div>
  );
};

const ProgramInformationSelectComponent = ({ setQuery, selectedValue }) => {
  const options = {
    prk_to_home__c: 'PRK to Home',
    icms__c: 'ICMS',
    rpss_unit__c: 'RPSS',
    inside_safe__c: 'Inside Safe',
  };

  const mkQuery = values => {
    return {
      query: {
        bool: {
          should: values.map(m => ({ match: { [m]: true } })),
        },
      },
      value: values,
    };
  };

  const [selectedFeatures, toggleSelectedFeature] = React.useReducer(
    (fs, f) => (fs.includes(f) ? fs.filter(g => g != f) : fs.concat([f])),
    selectedValue || []
  );

  // React.useEffect(() => {
  //   setQuery(mkQuery(selectedFeatures));
  // }, []);

  React.useEffect(() => {
    setQuery(mkQuery(selectedFeatures));
  }, [selectedFeatures]);

  React.useEffect(() => {
    // unselected features that have been cleared
    if (selectedValue == undefined && selectedFeatures.length) {
      selectedFeatures.map(selectedFeature =>
        toggleSelectedFeature(selectedFeature)
      );
    }
  }, [selectedValue]);

  return Object.keys(options).map(key => (
    <Button
      key={key}
      variant={selectedFeatures.includes(key) ? 'contained' : 'outlined'}
      onClick={() => toggleSelectedFeature(key)}>
      <label>{options[key]}</label>
    </Button>
  ));
};

const RentalFeaturesSelectComponent = ({ setQuery, selectedValue }) => {
  const features = {
    rentalFacts: {
      pets__c: 'Pets',
      credit_check__c: 'Credit Check',
      background_check__c: 'Background Check',
      no_evictions__c: 'No Evictions',
      x55_plus__c: '55+',
      x62_plus__c: '62+',
      parking__c: 'Parking',
      wheelchair_accessible__c: 'Wheelchair Accessible',
      appliances: 'Appliances',
      landlordPaidUtilities: 'Landlord Paid Utilities',
      prk_to_home__c: 'PRK to Home',
    },
    appliances: [
      'stove_range_oven__c',
      'refrigerator__c',
      'microwave__c',
      'dishwasher__c',
      'washer__c',
      'garbage_disposal__c',
    ],
    landlordPaidUtilities: [
      'gas__c',
      'water_sewer__c',
      'trash__c',
      'electricity__c',
      'heat_electric__c',
      'heat_gas__c',
      'common_area_charge__c',
    ],
  };

  const mkMatch = key => {
    switch (key) {
      case 'common_area_charge__c':
        return false;
      case 'no_evictions__c':
        return false;
      case 'washer__c':
        return {
          fuzziness: 'AUTO',
          query: 'Washer',
        };
      default:
        return true;
    }
  };

  const mkQuery = values => {
    let matches = values.includes('appliances')
      ? [...values.filter(f => f != 'appliances'), ...features.appliances]
      : values;

    matches = matches.includes('landlordPaidUtilities')
      ? [
          ...matches.filter(f => f != 'landlordPaidUtilities'),
          ...features.landlordPaidUtilities,
        ]
      : matches;

    return {
      query: {
        bool: {
          must: matches.map(m => ({ match: { [m]: mkMatch(m) } })),
        },
      },
      value: values,
    };
  };

  const [selectedFeatures, toggleSelectedFeature] = React.useReducer(
    (fs, f) => (fs.includes(f) ? fs.filter(g => g != f) : fs.concat([f])),
    selectedValue || []
  );

  React.useEffect(() => {
    setQuery(mkQuery(selectedFeatures));
  }, []);

  React.useEffect(() => {
    // unselected features that have been cleared
    if (selectedValue == undefined && selectedFeatures.length) {
      selectedFeatures.map(selectedFeature =>
        toggleSelectedFeature(selectedFeature)
      );
    }
  }, [selectedValue]);

  React.useEffect(() => {
    setQuery(mkQuery(selectedFeatures));
  }, [selectedFeatures]);

  return Object.keys(features.rentalFacts).map(key => (
    <Button
      key={key}
      variant={selectedFeatures.includes(key) ? 'contained' : 'outlined'}
      onClick={() => toggleSelectedFeature(key)}>
      <label>{features.rentalFacts[key]}</label>
    </Button>
  ));
};

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const GrowTransition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const InlineSelect = ({
  closePopover,
  componentId,
  savedQuery,
  searchState,
  renderSelect,
  buttonTitle,
  withPopover,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useMediaQuery('(max-width: 900px)');

  React.useEffect(() => {
    if (closePopover && anchorEl) {
      handleClose();
    }
  }, [closePopover]);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${componentId}-selection-popover` : undefined;
  const selectedValue =
    searchState?.[componentId]?.value || savedQuery.search.params[componentId];

  return (
    <ReactiveComponent
      componentId={componentId}
      render={({ setQuery }) => {
        return (
          <div style={{ display: 'inline-block' }}>
            {withPopover ? (
              <>
                <Button
                  aria-describedby={id}
                  variant="outlined"
                  onClick={handleClick}>
                  <label>{buttonTitle({ selectedValue, isMobile })}</label>
                </Button>
                <div style={{ display: 'none' }}>
                  {renderSelect({ setQuery, selectedValue })}
                </div>
                <Popover
                  classes={{ root: styles['filter-popover'] }}
                  id={id}
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  PaperProps={{ classes: { root: styles['popover-paper'] } }}
                  transitionDuration={{ enter: 500, exit: 1000 }}
                  TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                  }>
                  {renderSelect({ setQuery, selectedValue })}
                  <div className={styles['done-btn-row']}>
                    <Button
                      variant="outlined"
                      className={styles['done-btn']}
                      onClick={() => handleClose()}>
                      <label>Done</label>
                    </Button>
                  </div>
                </Popover>
              </>
            ) : (
              renderSelect({ setQuery, selectedValue })
            )}
          </div>
        );
      }}
    />
  );
};

export const saveQuery = authState => async (searchState, metaParams, id) => {
  const keys = [
    'BedRoom',
    'Location',
    'RentalFeatures',
    'RentalPrice',
    'ProgramInformation',
  ];
  let query = searchState.SearchResultMap.query;

  // HACK FOR HIDDEN UNITS
  // We don't want these stored in the query, they are already
  // stored at a higher level.
  query = {
    bool: {
      must: [
        {
          bool: {
            must: query.bool.must[0].bool.must.filter(
              x => !x.bool?.hasOwnProperty('must_not')
            ),
          },
        },
      ],
    },
  };

  axios.interceptors.request.use(function (config) {
    config.headers['x-jwt'] = authState.accessToken.accessToken;
    return config;
  });
  const params = {
    params: keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: searchState[key].value,
      }),
      {}
    ),
    name: metaParams.queryName,
    hmisId: metaParams.queryHmisId,
    clientSubsidy: metaParams.clientSubsidy,
    clientCaseManagementDurationInYears:
      metaParams.clientCaseManagementDurationInYears,
    clientHasEvictionInTheLastSevenYears:
      metaParams.clientHasEvictionInTheLastSevenYears,
    clientIncomeInDollars: metaParams.clientIncomeInDollars,
    userId: authState.accessToken.claims.uid,
    query,
    ...(metaParams.hiddenUnits ? { hiddenUnits: metaParams.hiddenUnits } : {}),
  };

  // TODO: Remove hidden units query somehow...
  // can't do this because then it remvoes them from the save
  // remove jhidden units from the query
  // then make sure it gets added on the server side

  // debugger;

  if (id) {
    return axios
      .put(`${findApiUrl()}/save-search/${id}`, params)
      .then(() => id);
  } else {
    return axios
      .post(`${findApiUrl()}/save-search`, params)
      .then(({ data }) => data.body._id);
  }
};

export const EMPTY_QUERY = { search: { params: {} } };

export const LocationSelect = ({
  closePopover,
  savedQuery,
  searchState,
  withPopover,
}) => (
  <InlineSelect
    buttonTitle={({ selectedValue, isMobile }) =>
      isMobile || !selectedValue ? 'Location' : selectedValue
    }
    closePopover={closePopover}
    componentId="Location"
    savedQuery={savedQuery}
    searchState={searchState}
    withPopover={withPopover}
    renderSelect={({ setQuery, selectedValue }) => {
      return (
        <LocationSelectComponent
          setQuery={setQuery}
          selectedValue={selectedValue}
        />
      );
    }}
  />
);

export const BedRoomSelect = ({
  closePopover,
  savedQuery,
  searchState,
  withPopover,
}) => {
  const mkQuery = value =>
    value
      ? {
          query: { match: { 'bedrooms__c.keyword': value } },
          value,
        }
      : {
          query: { match_all: {} },
          value,
        };
  return (
    <InlineSelect
      buttonTitle={({ selectedValue, isMobile }) =>
        isMobile || !selectedValue ? 'Beds' : selectedValue
      }
      closePopover={closePopover}
      componentId="BedRoom"
      savedQuery={savedQuery}
      searchState={searchState}
      withPopover={withPopover}
      renderSelect={({ setQuery, selectedValue }) => {
        return (
          <BedSelectComponent
            mkQuery={mkQuery}
            setQuery={setQuery}
            selectedValue={selectedValue}
          />
        );
      }}
    />
  );
};

export const RentalPriceSelect = ({
  closePopover,
  savedQuery,
  searchState,
  withPopover,
}) => (
  <InlineSelect
    buttonTitle={({ selectedValue, isMobile }) =>
      isMobile || !selectedValue ? 'Rent' : formatRangeLabel(selectedValue)
    }
    closePopover={closePopover}
    componentId="RentalPrice"
    savedQuery={savedQuery}
    searchState={searchState}
    withPopover={withPopover}
    renderSelect={({ setQuery, selectedValue }) => {
      return (
        <RentalPriceSelectComponent
          savedQuery={savedQuery}
          setQuery={setQuery}
          selectedValue={selectedValue}
        />
      );
    }}
  />
);

export const ProgramInformationSelect = ({
  closePopover,
  savedQuery,
  searchState,
  withPopover,
}) => (
  <InlineSelect
    buttonTitle={({ selectedValue }) => 'Program Information'}
    closePopover={closePopover}
    componentId="ProgramInformation"
    savedQuery={savedQuery}
    searchState={searchState}
    withPopover={withPopover}
    renderSelect={({ setQuery, selectedValue }) => {
      return (
        <div className={styles['filter-button-wrapper']}>
          <ProgramInformationSelectComponent
            setQuery={setQuery}
            selectedValue={selectedValue}
          />
        </div>
      );
    }}
  />
);

export const RentalFeaturesSelect = ({
  closePopover,
  savedQuery,
  searchState,
  withPopover,
}) => (
  <InlineSelect
    buttonTitle={({ selectedValue }) => 'Rental Features'}
    closePopover={closePopover}
    componentId="RentalFeatures"
    savedQuery={savedQuery}
    searchState={searchState}
    withPopover={withPopover}
    renderSelect={({ setQuery, selectedValue }) => {
      return (
        <div className={styles['filter-button-wrapper']}>
          <RentalFeaturesSelectComponent
            setQuery={setQuery}
            selectedValue={selectedValue}
          />
        </div>
      );
    }}
  />
);

export const initialAnswers = {
  queryName: '',
  queryHmisId: '',
  clientSubsidy: '',
  clientCaseManagementDurationInYears: '',
  clientHasEvictionInTheLastSevenYears: false,
  clientIncomeInDollars: '',
  hiddenUnits: [],
};

export const reducer = (state, { name, value, type }) => {
  switch (type) {
    case 'update':
      return { ...state, [name]: value };
    case 'init':
      return value;
    default:
      throw new Error();
  }
};

const validateAnswers = answers => {
  const {
    clientSubsidy,
    clientHasEvictionInTheLastSevenYears,
    hiddenUnits,
    ...requiredAnswers
  } = answers;
  return Object.values(requiredAnswers).every(Boolean);
};

export const UnitFilters = ({
  criteriaAnswers,
  currentSearchState,
  queryId,
  savedQuery,
  setCurrentSearchState,
}) => {
  const { pathname } = useLocation();
  const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
  const [answers, dispatch] = React.useReducer(reducer, initialAnswers);

  React.useEffect(() => {
    let initAnswers = null;
    if (queryId) {
      initAnswers = {
        queryName: savedQuery.search.name,
        queryHmisId: savedQuery.search.hmis_id,
        clientSubsidy: savedQuery.search.client_subsidy,
        clientCaseManagementDurationInYears:
          savedQuery.search.client_case_management_duration_in_years,
        clientHasEvictionInTheLastSevenYears:
          savedQuery.search.client_has_eviction_in_the_last_7_years,
        clientIncomeInDollars: savedQuery.search.client_income_in_dollars,
        hiddenUnits: savedQuery.search.hidden_units,
      };
    } else if (criteriaAnswers?.queryName) {
      initAnswers = criteriaAnswers;
    }
    if (!queryId && pathname.includes('saved-searches')) {
      setTimeout(() => {
        setOpenSaveDialog(true);
      }, 1000);
    }
    initAnswers && dispatch({ type: 'init', value: initAnswers });
  }, []);

  const handleUpdateFilters = searchState => {
    setOpenSaveDialog(true);
    setCurrentSearchState(searchState);
  };

  const { oktaAuth, authState } = useOktaAuth();

  return (
    <>
      <StateProvider
        renderError={error => (
          <div>
            Something went wrong!
            <br />
            Error details
            <br />
            {error}
          </div>
        )}
        onChange={(prev, next) => {
          setCurrentSearchState(next);
        }}
        includeKeys={['value', 'query']}
        strict={false}
        render={({ searchState }) => {
          return (
            <div className={styles['unit-filters']}>
              <LocationSelect
                withPopover
                closePopover={openSaveDialog}
                savedQuery={savedQuery}
                searchState={searchState}
              />

              <BedRoomSelect
                withPopover
                closePopover={openSaveDialog}
                savedQuery={savedQuery}
                searchState={searchState}
              />

              <RentalPriceSelect
                withPopover
                closePopover={openSaveDialog}
                savedQuery={savedQuery}
                searchState={searchState}
              />

              <RentalFeaturesSelect
                withPopover
                closePopover={openSaveDialog}
                savedQuery={savedQuery}
                searchState={searchState}
              />

              <ProgramInformationSelect
                withPopover
                closePopover={openSaveDialog}
                savedQuery={savedQuery}
                searchState={searchState}
              />

              {pathname.includes('explore') ? (
                <ResetFiltersButton className={styles['save-search-btn']} />
              ) : (
                <Button
                  classes={{ root: styles['save-search-btn'] }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleUpdateFilters(searchState)}>
                  <label>{`${queryId ? 'Update' : 'Save'} Search`}</label>
                </Button>
              )}
            </div>
          );
        }}
      />
      <Modal
        setOpen={setOpenSaveDialog}
        open={openSaveDialog}
        title="Save personalized results as:"
        btnOne="Cancel"
        btnTwo="Save"
        commitEnabled={validateAnswers(answers)}
        onCommit={() =>
          saveQuery(authState)(currentSearchState, answers, queryId)
            .then(id => {
              setOpenSaveDialog(false);
              toast.success('Successfully saved!');
              window.location.href = `/saved-searches/${id}`;
            })
            .catch(err => {
              console.error(err);
              toast.error(
                'There was an error, please contact support if the issue continues!'
              );
            })
        }>
        <Criteria dispatch={dispatch} criteriaAnswers={answers} />
      </Modal>
    </>
  );
};

const mapDispatchtoProps = dispatch => ({
  clearValues: () => dispatch(clearValues()),
});

const ResetFiltersButton = connect(
  null,
  mapDispatchtoProps
)(({ clearValues, className }) => {
  return (
    <Button
      classes={{ root: className }}
      color="primary"
      variant="contained"
      onClick={() => clearValues()}>
      <label>Reset Filters</label>
    </Button>
  );
});

export { ResetFiltersButton };

export const ReactiveToggle = props => {
  const { componentId = 'HiddenUnitsToggle' } = props;
  return (
    <ReactiveComponent
      componentId={componentId}
      render={({ setQuery }) => {
        return <ToggleHiddenUnits {...props} setQuery={setQuery} />;
      }}
    />
  );
};

const ToggleHiddenUnits = ({
  hiddenUnits = [],
  label = 'View Hidden Units',
  mkHiddenUnitsQuery,
  savedQueryId,
  setLoading,
  setQuery,
}) => {
  const [checked, toggleChecked] = React.useState(
    localStorage.getItem(savedQueryId) == 'true'
  );

  React.useEffect(() => {
    toggleQuery(checked);
    setLoading(false);
  }, []);

  const toggleUnits = () => {
    toggleChecked(state => {
      localStorage.setItem(savedQueryId, !state);
      toggleQuery(!state);
      return !state;
    });
  };

  const toggleQuery = viewHiddenUnits => {
    if (viewHiddenUnits && hiddenUnits.length) {
      setQuery({ query: { match_all: {} } });
    } else if (!viewHiddenUnits && hiddenUnits.length) {
      const hiddenUnitsQuery = mkHiddenUnitsQuery(hiddenUnits);
      setQuery(hiddenUnitsQuery);
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          disabled={!hiddenUnits.length}
          checked={checked}
          onChange={toggleUnits}
        />
      }
      label={label}
    />
  );
};
