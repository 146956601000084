import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      feedback: '',
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    let feedback = this.state.feedback;
    let provideFeedback = this.props.provideFeedback;
    provideFeedback(feedback);
    return this.handleClose();
  };

  change = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  style = {
    feedbackButton: {
      backgroundColor: '#7F2484',
      width: '115px',
      marginTop: '20px',
      color: 'white',
      borderRadius: '9999px',
      fontSize: '1rem',
    },
  };

  render() {
    return (
      <div id="buttonFeedback">
        <Button
          onClick={this.handleClickOpen}
          style={this.style.feedbackButton}
          color="inherit">
          Feedback
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            We'd love to hear from you!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Let us know what else you'd like to see on this app! We will try
              our best to make it happen{' '}
              <span role="img" aria-label="Smile Emoji">
                &#128578;
              </span>
              .
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="feedback"
              label="Feedback"
              type="text"
              fullWidth
              onChange={event => this.change(event)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
