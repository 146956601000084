import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      root: {
        padding: '2px 10px',
        textTransform: 'none',
        borderRadius: '3px',
        fontSize: '14px',
        '@media (min-width:600px)': {
          borderRadius: '0.5rem',
          padding: '5px 30px',
          fontSize: '0.875rem',
        },
      },
      // Name of the rule
      containedPrimary: {
        backgroundColor: '#0DB14B',
        color: '#fff',
        borderRadius: '9999px',
        '&:hover': {
          backgroundColor: 'rgb(137, 040, 144)',
          color: '#fff',
        },
        '@media (max-width:600px)': {
          padding: '3px 11px',
        },
      },
      outlined: {
        borderRadius: '3px',
        border: '1px solid #5C5C5C',
        padding: '2px 10px',
        '@media (min-width:600px)': {
          borderRadius: '0.5rem',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          padding: '5px 30px',
          color: '#5C5C5C',
        },
      },
      label: {
        '@media (max-width:600px)': {
          lineHeight: '20px',
        },
      },
    },
    MuiFab: {
      root: {
        backgroundColor: 'rgba(224,224,224,0.6)',
        '&:disabled': {
          backgroundColor: 'rgba(224,224,224,0.6)',
        },
        '&:hover': {
          backgroundColor: 'rgba(224,224,224,1)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0.5rem',
      },
    },
    MuiCard: {
      root: {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      },
    },
    MuiCardMedia: {
      root: {
        height: '160px',
        objectFit: 'cover',
        // paddingTop: '75%', // 4:3
        '@media (min-width:600px)': {
          height: '250px',
        },
      },
    },
    MuiCardContent: {
      root: {
        lineHeight: '20px',
        padding: '10px',
        '&:last-child': {
          paddingBottom: '10px',
        },
        '@media (min-width:600px)': {
          padding: '16px',
        },
      },
    },
    MuiDialog: {
      root: {
        '@media (max-width:900px)': {
          padding: '0px !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        '@media (max-width:600px)': {
          fontSize: '15px',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '@media (max-width:600px)': {
          fontSize: '15px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: '5px',
      },
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Sans Serif'].join(','),
    h3: {
      fontFamily: 'Jost',
      color: 'rgb(93, 94, 93)',
    },
    h4: {
      fontFamily: 'Jost',
    },
    h5: {
      fontFamily: 'Jost',
      '@media (max-width:600px)': {
        fontSize: '18px',
      },
    },
    h6: {
      fontFamily: 'Jost',
      '@media (max-width:600px)': {
        fontSize: '16px',
      },
    },
    subtitle2: {
      lineHeight: 'inherit',
      '@media (max-width:600px)': {
        fontSize: '14px',
      },
    },
    subtitle1: {
      '@media (max-width:600px)': {
        fontSize: '15px',
        lineHeight: '15px',
      },
    },
    body1: {
      fontFamily: ['Open Sans', 'Sans Serif'].join(','),
      '@media (max-width:600px)': {
        fontSize: '15px',
      },
    },
    body2: {
      '@media (max-width:600px)': {
        fontSize: '15px',
        lineHeight: 'inherit',
      },
    },
  },
  palette: {
    primary: {
      main: 'rgba(000, 174, 239, 80%)',
      dark: 'rgba(000, 174, 239, 100%)',
      light: 'rgba(000, 174, 239, 60%)',
    },
    secondary: {
      main: 'rgb(137, 040, 144)',
      contrastText: '#fff',
    },
    error: {
      main: '#ED1C24',
    },
    success: {
      main: '#0DB14B',
    },
    text: {
      primary: '#5C5C5C',
    },
    default: {
      main: '#F3F3F4',
    },
  },
});

export default responsiveFontSizes(theme);
