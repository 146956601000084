import React from 'react';
import PropTypes from 'prop-types';

//material-ui icons
import Commute from '@material-ui/icons/Commute';
import LocationSearching from '@material-ui/icons/LocationSearching';

const colors = [
  '#47AA2C',
  '#1EAFED',
  '#FEC1CB',
  '#7F0F7F',
  '#EA212E',
  'F7EB47',
  'F1B584',
  'ED9940',
  '#84CEE7',
  '#97C459',
];

const SearchStops = props => {
  const { unitSearchStops = [] } = props;

  if (unitSearchStops.length > 0) {
    //use map to create a list of stops
    const stops = unitSearchStops.map((stop, i) => (
      <div key={i} className="stop">
        <p>
          <LocationSearching
            style={{ marginRight: '1.5vh', color: colors[i] || 'purple' }}
          />
          {stop.name}
        </p>
        <ul>
          <li className="distance">
            {Math.round(stop.distance * 10) / 10 + ' mile'}
          </li>
          {stop.route_summary.map((route, j) => (
            <li className="stop name" key={j}>
              {route.name}
            </li>
          ))}
        </ul>
      </div>
    ));

    return (
      <div className="walkscore-framebox">
        <h1>Nearby Public Transit</h1>
        <Commute style={{ color: 'purple', fontSize: '30px' }} />
        <div className="walkscore-api-container searchStops">{stops}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default SearchStops;

SearchStops.propTypes = {
  unitSearchStops: PropTypes.array,
};
