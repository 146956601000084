//all the functions from dashboard are here
import axios from 'axios';
import firebase from 'path-firebase/firebase';
import { Dashboard } from '../Dashboard.js';
import { findApiUrl } from '../../config/env';

const apiURL = findApiUrl();
//Firebase Functions
export const trackLogin = state => {
  //Track the number of times the user logs into the app - each time the Dashboard runs === logging in once
  //using slice to get rid of the domain name of each email before saving. No special symbols (@, :, etc) are allowed on Firebase DB)
  // const usersEmail = state.firebaseUsername;
  // let today_date = new Date().toJSON().slice(0, 10).toString();
  // const user_ref = firebase.database().ref(`login-tracking/${usersEmail}`);
  // let user_record = {};
  // return user_ref.once('value').then(snapshot => {
  //   //using callback because it's a promise
  //   user_record = Object.assign({}, snapshot.val());
  //   //check to see if the date is alredy present as a key. If so, add 1; else, set it equal to 1.
  //   user_record[today_date] = user_record[today_date] + 1 || 1;
  //   user_ref.update(user_record);
  // });
};

/**
 * Retrieving favorite units from Firebase Database
 */
export function retrieveFavorites(state) {
  // const setState = this.setState.bind(Dashboard);
  // const uid = state.uid;
  // const itemRef = firebase.database().ref(`items/${uid}`);
  // itemRef.on('value', snapshot => {
  //   if (snapshot.val()) {
  //     let favorites = snapshot.val()['unitId'];
  //     setState({ favorites });
  //   } else {
  //     setState({ favorites: [] });
  //   }
  // });
  return;
}

/**
 * If the user agrees, it will update the firebase database.
 */
export const setShowAgreementToTrue = state => {
  const usersEmail = state.firebaseUsername;
  let date = new Date().toJSON().slice(0, 10).toString();
  const termsRef = firebase.database().ref(`terms-tracking/${usersEmail}`);
  termsRef.push({
    usersEmail,
    date,
  });
  return;
};
/**
 * Track View History
 * Run as a setState callback in the sendDetails method
 */
export const trackViewHistory = (state, id) => {
  const unit = { ...state.units[id] };
  const unitId = unit.Id;
  const usersEmail = state.firebaseUsername;
  const viewRef = firebase
    .database()
    .ref(`units-views/${unitId}/${usersEmail}`);
  const timeStamp = Date.now();
  viewRef.push(timeStamp);
  return;
};

/**
 * input: an array of ids of recommended units
 * output: an object with id as th key and unit info as the value
 * unit info includes name, area, and rent
 */

export const getRecsDetails = (recs, units) => {
  return Object.keys(recs).reduce((acc, key) => {
    const us = units.filter(u => recs[key].includes(u.id));
    return {
      ...acc,
      [key]: us.map(u => ({
        id: u.id,
        name: u.name,
        area: u.spa__c,
        rent: u.account_rent__c,
      })),
    };
  }, {});
};

/**
 * Calling API Request to WalkScore/TransitScore
 * Response is an object
 * Is called in the function - sendDetails
 */
export const walkScoreData = async (lat, lon) => {
  if (lat === undefined || lon === undefined) {
    return;
  }
  try {
    const response = await axios.get(`${apiURL}/walkscore`, {
      params: {
        lat: lat,
        lon: lon,
      },
    });
    return response.data;
  } catch (err) {
    return;
  }
};

export const TransitScoreData = async (lat, lon) => {
  const cities = {
    la: 'LosAngeles',
    sb: 'SantaBarbara',
  };

  if (lat === undefined || lon === undefined) {
    return;
  }
  try {
    const response = await axios.get(
      `${apiURL}/transitScore/${cities[this.props.region]}`,
      {
        params: {
          lat: lat,
          lon: lon,
        },
      }
    );
    return response.data;
  } catch (err) {
    return;
  }
};

export const SearchStopsData = async (lat, lon) => {
  if (lat === undefined || lon === undefined) {
    return;
  }
  try {
    const response = await axios.get(`${apiURL}/searchStops`, {
      params: {
        lat: lat,
        lon: lon,
      },
    });
    return response.data;
  } catch (err) {
    return;
  }
};

export const checkRentalFeatures = (
  unit,
  filterReturnValues,
  features,
  typeOfFeature
) => {
  filterReturnValues[typeOfFeature] = false;
  for (const feature of features) {
    if (unit[feature]) {
      filterReturnValues[typeOfFeature] = true;
      break;
    }
  }
};

export const checkOtherFeatures = (unit, filterReturnValues, rentalFacts) => {
  const filtered = rentalFacts.filter(feature => unit[feature]);
  filterReturnValues['rentalFacts'] = filtered.length === rentalFacts.length;
};
