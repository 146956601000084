import React from 'react';

/**
 *
 * Summary: Uses the React Context API to pass down
 * the authenticated user object to the other
 * components
 *
 */

const AuthUserContext = React.createContext(null);

export default AuthUserContext;
