import * as React from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import styles from '../style.module.scss';

const HideUnitIcon = ({
  hidden,
  setViewHideUnit,
  viewHideUnit,
  viewDetails,
}) => {
  return (
    <>
      {viewDetails ? (
        <Button
          color="action"
          className={styles.visibilityOffIcon}
          onClick={() => setViewHideUnit(!viewHideUnit)}
          title={!hidden && 'Hide'}
          startIcon={
            <VisibilityOffIcon color={hidden ? 'secondary' : 'action'} />
          }>
          {Boolean(hidden) ? 'Hidden' : 'Hide'} Unit
        </Button>
      ) : (
        <Fab
          aria-label="Hide"
          color="default"
          className={styles.icon}
          onClick={() => setViewHideUnit(!viewHideUnit)}
          size="small"
          title="Hide">
          <VisibilityOffIcon color={hidden ? 'secondary' : 'action'} />
        </Fab>
      )}
    </>
  );
};

export default HideUnitIcon;
