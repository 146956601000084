import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//material-ui
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Accessibility from '@material-ui/icons/Accessibility';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

//components
import RentalFacts from './RentalFacts.js';
import UnitWalkScore from './UnitWalkScore.js';
import SearchStops from './SearchStops.js';

const bedroom = 'bedrooms__c';
const bathroom = 'bathrooms__c';
const spa = 'spa__c';
const description = 'description';
const prk = 'prk_to_home__c';
const programType = 'program_type__c';

const formatBeds = beds => {
  const parsed = parseInt(beds, 10);
  return isNaN(parsed) ? beds : parsed > 1 ? `${beds} Beds` : `1 Bed`;
};

const formatBaths = baths => `${baths} Baths`;

const initialState = {
  unitWalkScore: {},
  unitTransitScore: {},
  unitSearchStops: [],
  details: {},
  address: '',
  unitContactId: '',
};

class UnitDetails extends React.Component {
  constructor(props) {
    super();
    this.state = { ...initialState };
  }

  componentDidMount() {
    const {
      children,
      unitWalkScore,
      unitTransitScore,
      unitSearchStops,
    } = this.props;

    let details = children(this.props.unitDetails);
    let address = details.billingaddress;
    let unitContactId = details.unit_id__c;
    if (!address) {
      address = details.name || '';
    }

    this.setState({
      unitWalkScore,
      unitTransitScore,
      unitSearchStops,
      details,
      address,
      unitContactId,
    });
  }

  render() {
    const { details, address, unitContactId } = this.state;
    const { unitWalkScore, unitSearchStops, unitTransitScore } = this.props;

    return (
      <div className="container">
        <Typography variant="h4">{address.street}</Typography>
        <div style={{ margin: '0.5rem 0' }}>
          <Chip
            style={{ marginRight: '0.5rem' }}
            variant="outlined"
            label={formatBeds(details[bedroom])}
          />
          <Chip variant="outlined" label={formatBaths(details[bathroom])} />
        </div>

        {this.props.unitDetails?.recordtype?.Name !== 'Public Listing' ? (
          <Chip icon={<CheckCircleIcon />} label="Verified" color="secondary" />
        ) : null}
        <div className="unit-detail-section">
          <Typography variant="h6">Address</Typography>
          <div className="unit-detail-row">
            <LocationOnIcon fontSize="inherit" />
            <Typography variant="subtitle1">{`${details.name}`}</Typography>
          </div>
        </div>
        <div className="unit-detail-section">
          <Typography variant="h6">Account Details</Typography>
          {details.unit_number__c && (
            <Typography variant="body1">
              {'Unit Number - '}
              {details.unit_number__c}
            </Typography>
          )}
          <Typography variant="body1">{details[spa]}</Typography>
          <Typography variant="body1">
            Unit Designation: {details[prk] ? 'PRK to Home' : 'Available'}
          </Typography>
          <Typography variant="body1">
            Program Type: {details[programType]}
          </Typography>
        </div>
        <div style={{ margin: '0.5rem 0' }}>
          <p>{details[description]}</p>
        </div>

        <Typography variant="h5">Unit Features</Typography>
        <div className="rentalFacts">
          <RentalFacts unitDetails={details} />
        </div>
        <div className="unitWalkScore">
          <h1>Neighborhood Accessibility</h1>
          <Accessibility style={{ fontSize: '30px', color: '#2CB0EA' }} />
          <UnitWalkScore
            unitWalkScore={unitWalkScore}
            unitTransitScore={unitTransitScore}
          />
          <SearchStops unitSearchStops={unitSearchStops} />
        </div>
      </div>
    );
  }
}

UnitDetails.propTypes = {
  children: PropTypes.func,
  favorites: PropTypes.array,
  unitWalkScore: PropTypes.object,
  unitTransitScore: PropTypes.object,
  unitSearchStops: PropTypes.array,
};

export default UnitDetails;
