import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import App from './App/App.js';
import registerServiceWorker from './registerServiceWorker';
import { toast } from 'react-toastify';
import ReloadToast from './Components/ReloadToast.js';

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root')
);

const reloadToast = () => toast(ReloadToast);
registerServiceWorker(reloadToast);
