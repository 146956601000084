import React from 'react';
import { Link } from 'react-router-dom';

const ReloadToast = () => (
  <div>
    <Link
      to="/saved-searches"
      onClick={() => window.location.reload()}
      style={{ color: '#52c1ec', textDecoration: 'none' }}>
      Click here to view updated content!
    </Link>
  </div>
);

export default ReloadToast;
