require('dotenv').config();
const env = process.env.NODE_ENV;

export const findApiUrl = () => {
  if (env === 'development') {
    return process.env.REACT_APP_DEV_API;
  } else if (env === 'production') {
    return process.env.REACT_APP_PROD_API;
  } else {
    console.error(
      'Environment undefined, please add NODE_ENV and REACT_APP_DEV_API or REACT_APP_PROD_API to your .env file!'
    );
    return '';
  }
};

export const getElasticCredentials = () =>
  process.env.REACT_APP_ELASTIC_SEARCH_CREDENTIALS;
