import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

//components
import Map from 'Components/Map.js';
import UnitDetails from './Details/UnitDetails.js';
import ContactInfo from './Details/ContactInfo.js';
import CarouselContainer from './ImageGallery/Carousel.js';
import FullDialog from 'Components/FullDialog';

import {
  walkScoreData,
  TransitScoreData,
  SearchStopsData,
} from 'Dashboard/functions';

const ViewDetails = props => {
  const {
    email,
    unitDetails,
    unitCoordinates,
    savedQueryId,
    criteriaAnswers,
    setShowModal,
    showModal,
    renderHideUnitIcon = () => {},
  } = props;
  const [unitWalkScore, setUnitWalkScore] = React.useState({});
  const [unitTransitScore, setUnitTransitScore] = React.useState({});
  const [unitSearchStops, setUnitSearchStops] = React.useState([]);

  React.useEffect(() => {
    showModal && sendDetails();
  }, [showModal]);

  const sendDetails = () => {
    const lat = unitDetails.geocodes?.lat || '';
    const lon = unitDetails.geocodes?.lng || '';
    walkScoreData(lat, lon).then(setUnitWalkScore);
    TransitScoreData(lat, lon).then(setUnitTransitScore);
    SearchStopsData(lat, lon).then(setUnitSearchStops);
  };
  return (
    <FullDialog
      setShowModal={setShowModal}
      showModal={showModal}
      renderHideUnitIcon={renderHideUnitIcon}>
      <div className="viewDetails">
        <div className="backButton" />
        <div className="topDetails">
          <CarouselContainer unitDetailsId={unitDetails.id} />
          <div className="unitMap">
            <Map unitCoordinates={unitCoordinates} />
          </div>
        </div>
        <div className="bottomDetails">
          <div className="unitDetails">
            <UnitDetails
              unitDetails={unitDetails}
              unitWalkScore={unitWalkScore}
              unitTransitScore={unitTransitScore}
              unitSearchStops={unitSearchStops}>
              {unitDetails =>
                unitDetails === undefined ? (
                  <ReactLoading
                    type="spinningBubbles"
                    color="green"
                    height={'20%'}
                    width={'20%'}
                  />
                ) : (
                  unitDetails
                )
              }
            </UnitDetails>
          </div>
          <div className="contactInfoContainer">
            <ContactInfo
              unitDetails={unitDetails}
              email={email}
              sendDetails={sendDetails}
              criteriaAnswers={criteriaAnswers}
              savedQueryId={savedQueryId}
            />
          </div>
        </div>
      </div>
    </FullDialog>
  );
};

ViewDetails.propTypes = {
  unitDetails: PropTypes.object,
  unitCoordinates: PropTypes.array, // [geocodes.lat, geocodes.lng]
  email: PropTypes.string, // unit_contact_email__c
  savedQueryId: PropTypes.string,
  criteriaAnswers: PropTypes.object,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
};

export default ViewDetails;
