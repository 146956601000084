import * as React from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';

import styles from './styles.module.scss';

// long transform to USD without fraction
const transformToUSD = num => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};

export const Criteria = ({ criteriaAnswers, dispatch, required = true }) => {
  const {
    queryName,
    queryHmisId,
    clientSubsidy,
    clientCaseManagementDurationInYears,
    clientHasEvictionInTheLastSevenYears,
    clientIncomeInDollars,
  } = criteriaAnswers;

  const handleIncome = ({ target: { value } }) => {
    const incomeNum = value.replace(/[^0-9]+/g, '');
    dispatch({
      name: 'clientIncomeInDollars',
      type: 'update',
      value: Number(incomeNum),
    });
  };

  const handleIncomeDisplay = () =>
    Number(clientIncomeInDollars) ? transformToUSD(clientIncomeInDollars) : '';

  return (
    <>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Search Name"
        type="text"
        variant="outlined"
        fullWidth
        required={required}
        value={queryName}
        onChange={e =>
          dispatch({ name: 'queryName', type: 'update', value: e.target.value })
        }
      />
      <TextField
        margin="dense"
        id="hmis-id"
        label="HMIS ID"
        type="text"
        variant="outlined"
        fullWidth
        required={required}
        value={queryHmisId}
        onChange={e =>
          dispatch({
            name: 'queryHmisId',
            type: 'update',
            value: e.target.value,
          })
        }
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel id="subsidy-select-label" margin="dense">
          Select Subsidy
        </InputLabel>
        <Select
          labelId="subsidy-select-label"
          id="subsidy-select"
          label="Select Subsidy"
          margin="dense"
          value={clientSubsidy}
          onChange={e =>
            dispatch({
              name: 'clientSubsidy',
              type: 'update',
              value: e.target.value,
            })
          }>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="FHSP">FHSP</MenuItem>
          <MenuItem value="HACLA">HACLA</MenuItem>
          <MenuItem value="HASB">HASB</MenuItem>
          <MenuItem value="LB/OC">LB/OC</MenuItem>
          <MenuItem value="RRH">RRH</MenuItem>
          <MenuItem value="SSVF">SSVF</MenuItem>
          <MenuItem value="TBRA">TBRA</MenuItem>
          <MenuItem value="VASH">VASH</MenuItem>
          <MenuItem value="HACoLA">HACoLA</MenuItem>
          <MenuItem value="EHV">EHV</MenuItem>
        </Select>
      </FormControl>
      <TextField
        classes={{ root: styles['case-management-duration'] }}
        margin="dense"
        id="client-case-management-duration-in-years"
        label="Client Case Management Duration (In Years)"
        type="number"
        variant="outlined"
        fullWidth
        required={required}
        value={clientCaseManagementDurationInYears}
        onChange={e =>
          dispatch({
            name: 'clientCaseManagementDurationInYears',
            type: 'update',
            value: e.target.value,
          })
        }
      />
      <TextField
        margin="dense"
        id="client-income-in-dollars"
        label="Client Income"
        type="text"
        variant="outlined"
        fullWidth
        required={required}
        value={handleIncomeDisplay()}
        onChange={handleIncome}
      />
      <FormControlLabel
        control={
          <Switch
            checked={clientHasEvictionInTheLastSevenYears}
            id="client-has-eviction-in-the-last-seven-years"
            onChange={() =>
              dispatch({
                name: 'clientHasEvictionInTheLastSevenYears',
                type: 'update',
                value: !clientHasEvictionInTheLastSevenYears,
              })
            }
          />
        }
        label="Client has been evicted in the last 7 years"
      />
    </>
  );
};
