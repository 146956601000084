import * as React from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import Modal from 'Components/Matching/Modal';

import WideCard from 'Components/WideCard';

import './styles.css';

// long transform to USD without fraction
const transformToUSD = num =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);

const SavedSearch = ({ savedQuery, onDeleteSavedQuery }) => {
  const [title, setTitle] = React.useState('');
  const [hmis, setHmis] = React.useState('');
  const [income, setIncome] = React.useState('');
  const [duration, setDuration] = React.useState('');
  const [eviction, setEviction] = React.useState(false);
  const [subsidy, setSubsidy] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const match = useRouteMatch();

  const get = q => prop => q.search && q.search[prop];

  React.useEffect(() => {
    setTitle(get(savedQuery)('name'));
    setHmis(get(savedQuery)('hmis_id'));

    setIncome(get(savedQuery)('client_income_in_dollars'));
    setDuration(get(savedQuery)('client_case_management_duration_in_years'));
    setEviction(get(savedQuery)('client_has_eviction_in_the_last_7_years'));
    setSubsidy(get(savedQuery)('client_subsidy'));
  }, [savedQuery._id]);

  const handleDeleteSearchWithConfirmation = () => {
    onDeleteSavedQuery(savedQuery);
  };

  return (
    <WideCard>
      <Modal
        open={open}
        title={`Are you sure you want to delete '${title}'?`}
        btnTwo="Yes, delete"
        btnOne="No, don't delete"
        onCommit={handleDeleteSearchWithConfirmation}
        setOpen={setOpen}
      />
      <div className="row row-one">
        <div className="item item-one">
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">
            <span className="bold-subtitle">HMIS:</span>
            {hmis}
          </Typography>
          <Typography variant="subtitle2">
            <span className="bold-subtitle">Income:</span>
            {transformToUSD(income)}
          </Typography>
          <Typography variant="subtitle2">
            <span className="bold-subtitle">Case Management Duration:</span>
            {` ${duration} yr${Number(duration) > 1 ? 's' : ''}`}
          </Typography>
          <Typography variant="subtitle2">
            <span className="bold-subtitle">Evicted in the last 7 years:</span>
            {eviction ? ' yes' : ' no'}
          </Typography>
          {subsidy ? (
            <Typography variant="subtitle2">
              <span className="bold-subtitle">Subsidy:</span>
              {subsidy}
            </Typography>
          ) : null}
        </div>
        <div className="item item-two">
          <IconButton disableRipple>
            <Button
              href={`${match.path}/${savedQuery._id}`}
              variant="outlined"
              color="secondary">
              Manage
            </Button>
          </IconButton>
          <IconButton onClick={() => setOpen(true)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </WideCard>
  );
};

export default SavedSearch;
