import React from 'react';

const StepWrapper = ({
  children,
  activeStep,
  currentStep,
  steps,
  cardClass = 'card-content',
}) => {
  const stepClass = currentStep === activeStep ? 'show' : 'hide';
  return (
    <div className={stepClass}>
      <h3 className="card-header">{steps[activeStep]}</h3>
      <div className={cardClass}>{children}</div>
    </div>
  );
};

export default StepWrapper;
