import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import styles from './styles.module.scss';

const Location = ({ selectedValue, spaData, setQuery }) => {
  const [spa, setSPA] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');

  const clearQuery = () =>
    setQuery({
      query: { match_all: {} },
      value: null,
    });

  React.useEffect(() => {
    if (selectedValue) {
      if (Number(selectedValue)) {
        setPostalCode(selectedValue);
      } else {
        setSPA(selectedValue);
      }
    }
  }, []);

  React.useEffect(() => {
    if (spa) {
      spa === 'Location'
        ? clearQuery()
        : setQuery({
            query: { match: { 'spa__c.keyword': spa } },
            value: spa,
          });
    }
    if (postalCode.length >= 5) {
      const query = {
        query: {
          match: { 'billingaddress.postalCode': postalCode },
        },
      };
      setQuery({ query, value: postalCode });
    }
  }, [spa, postalCode, setQuery]);

  const handlePostalChange = ({ target: { value } }) => {
    if (!value) {
      clearQuery();
    }
    setPostalCode(value);
  };
  const handleSpaChange = ({ target: { value } }) => setSPA(value);

  return (
    <div className={styles['location-wrapper']}>
      <TextField
        id="outlined-basic"
        label="Search by ZIP code"
        margin="dense"
        onChange={handlePostalChange}
        variant="outlined"
        value={postalCode}
      />
      <Typography variant="h6">Or</Typography>
      <FormControl variant="outlined">
        <InputLabel id="spa-select-label" margin="dense">
          SPA
        </InputLabel>
        <Select
          id="spa-select"
          labelId="spa-select-label"
          label="SPA"
          margin="dense"
          onChange={handleSpaChange}
          value={spa}>
          <MenuItem value="Location">
            <em>All</em>
          </MenuItem>
          {spaData.map(({ value, label }, idx) => (
            <MenuItem key={idx} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Location;
