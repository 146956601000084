import React from 'react';
import PropTypes from 'prop-types';

//for AbortController to cancel ongoing fetch requests
import 'abortcontroller-polyfill';
import Button from '@material-ui/core/Button';

class MessageForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      buttonText: 'Send Message',
      name: '',
      agency: '',
      subsidy: '',
      senderEmail: '',
      phone: '',
      comments: '',
      mounted: true,
    };
  }

  componentDidMount() {
    const { senderEmail = '' } = this.props;
    this.setState({ senderEmail });
  }

  AbortController = new window.AbortController();

  componentWillUnmount() {
    this.setState({
      mounted: false,
    });
    this.AbortController.abort();
  }

  handleChange = (el, key) => {
    this.setState({
      [key]: el.target.value,
    });
  };

  onKeyPress = e => {
    if (e.which === 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }
    const { name, agency, subsidy, phone, comments } = this.state;
    const { unit_id, senderEmail } = this.props;
    if (
      name === '' ||
      agency === '' ||
      subsidy === '' ||
      phone === '' ||
      comments === ''
    ) {
      alert('Please fill out all the fields.');
      return;
    }
    this.setState({ buttonText: 'Loading' });
    let response = false;
    //cut down the last three letters for the right salesforce account ID for the unit
    // const unit_id_salesforce = unit_id.split ('').slice (0, -3).join ('');
    try {
      const status = await fetch('https://formcarry.com/s/nVOQrzu9O0a', {
        signal: this.AbortController.signal,
        method: 'POST',
        body: JSON.stringify({
          unit_id: unit_id,
          email: senderEmail,
          comments,
          name,
          phone,
          subsidy,
          agency,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      });
      if (status) {
        response = true;
      }
    } catch (e) {
      this.setState({ buttonText: 'Message Failed' });
    }
    if (response && this.state.mounted) {
      this.setState({ buttonText: 'Message Sent!', comments: '' });
    } else {
      if (this.state.mounted) {
        this.setState({ buttonText: 'Message Failed!' });
      }
    }
  };

  render() {
    const { accountOwnerName } = this.props;
    return (
      <div id="message-form" className="message" style={{ display: 'none' }}>
        <div className="user-info">
          <span className="input container">
            <input
              className="input-border"
              type="text"
              onChange={el => this.handleChange(el, 'name')}
              value={this.state.name}
              placeholder="Name"
              required
            />
          </span>
          <span className="input container">
            <input
              className="input-border"
              type="text"
              onChange={el => this.handleChange(el, 'agency')}
              value={this.state.agency}
              placeholder="Agency"
              required
            />
          </span>
          <span className="input container">
            <input
              className="input-border"
              type="text"
              onChange={el => this.handleChange(el, 'phone')}
              value={this.state.phone}
              placeholder="Phone"
              required
            />
          </span>
        </div>
        <div className="comments">
          <span className="input container">
            <textarea
              className="input-border"
              type="text"
              cols="20"
              rows="4"
              placeholder={`Write Message to ${accountOwnerName}...`}
              onChange={el => this.handleChange(el, 'comments')}
              value={this.state.comments}
            />
          </span>
        </div>
        <div className="button">
          {' '}
          <Button
            type="submit"
            className="contactButton"
            color="primary"
            style={{ fullWidth: true, fontFamily: 'Open Sans, sans-serif' }}
            onClick={event => this.handleSubmit(event)}>
            {this.state.buttonText}
          </Button>
        </div>
      </div>
    );
  }
}

MessageForm.propTypes = {
  recipient: PropTypes.string,
  recipient_id: PropTypes.string,
  unit_title: PropTypes.string,
  unit_id: PropTypes.string,
  senderEmail: PropTypes.string,
};

export default MessageForm;
