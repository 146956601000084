import React from 'react';
import PropTypes from 'prop-types';

//Walkscore
const UnitWalkScore = props => {
  const { unitWalkScore = { walkscore: null }, unitTransitScore = {} } = props;
  let walkscore_status = false;
  let transit_status = false;
  let bike_status = false;
  if (
    unitWalkScore.walkscore !== null ||
    unitWalkScore.walkscore !== undefined
  ) {
    walkscore_status = true;
  }
  if (
    unitTransitScore.transit_score &&
    unitTransitScore.transit_score !== null
  ) {
    transit_status = true;
  }
  if (unitWalkScore.bike && unitWalkScore.bike.score !== undefined) {
    bike_status = true;
  }
  return (
    <div className="walkscore-api-container">
      {walkscore_status && (
        <div className="score walk">
          <div className="walkscore_logo">
            <a
              href={unitWalkScore.help_link}
              target="_blank"
              rel="noopener noreferrer">
              Walk Score®
            </a>
          </div>
          <div className="walkscore_score">
            <a
              className="walkscore_score-link"
              href={unitWalkScore.help_link}
              target="_blank"
              rel="noopener noreferrer">
              {unitWalkScore.walkscore ? (
                unitWalkScore.walkscore
              ) : (
                <p style={{ fontSize: '20px' }}>Not Available</p>
              )}
            </a>
          </div>
          <div className="walkscore_description">
            <div>{unitWalkScore.description}</div>
          </div>
        </div>
      )}
      {transit_status && (
        <div className="score transit">
          <div className="walkscore_logo">
            <a
              href={unitWalkScore.help_link}
              target="_blank"
              rel="noopener noreferrer">
              Transit Score®
            </a>
          </div>
          <div className="walkscore_score">
            <a
              className="walkscore_score-link"
              href={unitWalkScore.help_link}
              target="_blank"
              rel="noopener noreferrer">
              {unitTransitScore.transit_score}
            </a>
          </div>
          <div className="walkscore_description">
            <div>{unitTransitScore.description}</div>
          </div>
        </div>
      )}
      {bike_status && (
        <div className="score bike">
          <div className="walkscore_logo bike-logo">
            {/* <div>
                <Bike style={{ fontSize: '33px' }} />
              </div> */}
            <div className="walkscore_logo">
              <a
                href={unitWalkScore.help_link}
                target="_blank"
                rel="noopener noreferrer">
                Bike Score®
              </a>
            </div>
          </div>
          <div className="walkscore_score">
            <a
              className="walkscore_score-link"
              href={unitWalkScore.help_link}
              target="_blank"
              rel="noopener noreferrer">
              {unitWalkScore.bike.score}
            </a>
          </div>
          <div className="walkscore_description">
            <div>{unitWalkScore.bike.description}</div>
          </div>
        </div>
      )}
    </div>
  );
};

UnitWalkScore.propTypes = {
  unitWalkScore: PropTypes.object,
  unitTransitScore: PropTypes.object,
};

export default UnitWalkScore;
