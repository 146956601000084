import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { withCookies } from 'react-cookie/cjs';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import {
  DataSearch,
  ReactiveBase,
  ReactiveList,
} from '@appbaseio/reactivesearch';
import { useOktaAuth } from '@okta/okta-react';

import { Nav } from 'Components/Nav';
import withAuthorization from '../../withAuthorization';
import Form from '../Form';
import SavedSearch from '../SavedSearch';
import axios from 'axios';
import { getElasticCredentials, findApiUrl } from 'config/env';

import { EmptyState } from './EmptyState';
import { SavedSearchDetail } from '../SavedSearchDetail';
import Loader from 'Components/Loader';
import './styles.css';

const INDEX = 'saved-searches';

const getSavedQueries = uid => async (setSavedQueries, setIsLoading) => {
  axios
    .get(`${findApiUrl()}/saved-searches`, {
      params: { userId: uid },
    })
    .then(({ data }) => {
      setSavedQueries(data);
      setIsLoading(false);
    })
    .catch(err => {
      setIsLoading(false);
      setSavedQueries([]);
      console.error({ err });
    });
};

const MatchingDashboard = props => {
  const email = props.email;
  const [newSearch, setNewSearch] = React.useState(false);
  const [savedQueries, setSavedQueries] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const { oktaAuth, authState } = useOktaAuth();

  const match = useRouteMatch();

  axios.interceptors.request.use(function (config) {
    config.headers['x-jwt'] = authState.accessToken.accessToken;
    return config;
  });

  React.useEffect(() => {
    getSavedQueries(authState.accessToken.claims.uid)(
      setSavedQueries,
      setIsLoading
    );
  }, []);

  React.useEffect(() => {}, [savedQueries]);

  const handleClick = fn => e => {
    fn();
  };

  const startNewSearch = () => {
    setNewSearch(true);
  };

  const handleDeleteSavedQuery = uid => savedQuery => async e => {
    setIsLoading(true);
    axios
      .delete(`${findApiUrl()}/delete-search`, {
        headers: {
          ['x-jwt']: authState.accessToken.accessToken,
        },
        data: { id: savedQuery._id },
      })
      .then(({ data }) => getSavedQueries(uid)(setSavedQueries, setIsLoading))
      .catch(err => {
        console.error(err);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/search`}>
        <Nav email={email} />
        <Container maxWidth="xl" classes={{ root: 'content' }}>
          <Form />
        </Container>
      </Route>
      <Route path={`${match.path}/:savedQueryId`}>
        <Nav email={email} />
        <Container maxWidth="xl" classes={{ root: 'content' }}>
          <SavedSearchDetail savedQueries={savedQueries} />
        </Container>
      </Route>
      <Route path={match.path}>
        <Nav email={email} />
        <Container classes={{ root: 'content' }}>
          <div className="dashboard-row">
            <Typography
              align="center"
              variant="h4"
              classes={{ root: 'mobile-only' }}>
              Review Saved Searches
            </Typography>
            <Typography
              classes={{ root: 'hide-on-native-mobile' }}
              variant="h4">
              Review Saved Searches
            </Typography>
            {savedQueries.length ? (
              <Button
                href={`${match.url}/search`}
                variant="contained"
                color="primary"
                onClick={handleClick(startNewSearch)}>
                New Search
              </Button>
            ) : null}
          </div>

          {isLoading ? (
            <Loader />
          ) : savedQueries.length ? (
            <ReactiveBase
              app={INDEX}
              credentials={`${getElasticCredentials()}`}
              url="https://search-tenant-data-f7bm7cpqdpcux7ntn33sccpnym.us-west-2.es.amazonaws.com">
              <div className="data-search">
                <DataSearch
                  componentId="SavedSearch"
                  dataField={[
                    'search.hmis_id',
                    'search.name',
                    'search.name.search',
                  ]}
                  innerClass={{ input: 'data-search-input' }}
                  placeholder="Type the name of a Saved Search or HMIS ID"
                  showIcon={false}
                  debounce={250}
                />
              </div>
              <ReactiveList
                componentId="UnitResults"
                dataField="search.hmis_id"
                loader={<Loader />}
                showResultStats={false}
                react={{
                  and: ['SavedSearch'],
                }}
                render={({ loading, error, data }) =>
                  data.map((savedQuery, index) => (
                    <SavedSearch
                      key={index}
                      index={index}
                      savedQuery={savedQuery}
                      newSearch={newSearch}
                      onDeleteSavedQuery={handleDeleteSavedQuery(
                        authState.accessToken.claims.uid
                      )(savedQuery)}
                    />
                  ))
                }
                defaultQuery={function (value, props) {
                  return {
                    query: {
                      match: {
                        'search.user_id': authState.accessToken.claims.uid,
                      },
                    },
                  };
                }}
                renderNoResults={() => (
                  <Typography>
                    No saved searches match your search. Try searching again!
                  </Typography>
                )}
              />
            </ReactiveBase>
          ) : (
            <EmptyState
              title="No Saved Searches"
              description="Get started by creating a new search!">
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick(startNewSearch)}>
                <AddIcon className="plus-icon" />
                <span className="remove-link-styling">
                  <Link to={`${match.url}/search`}>New Search</Link>
                </span>
              </Button>
            </EmptyState>
          )}
        </Container>
      </Route>
    </Switch>
  );
};

export default withAuthorization(withCookies(MatchingDashboard));
