import Dashboard from '../Dashboard/Dashboard.js';
import PasswordForgetPage from '../Components/PasswordForget.js';
import MatchingDashboard from '../Components/Matching/MatchingDashboard/index.js';
import Appointments from 'Components/Appointments';
import MobileMore from 'Components/MobileMore';
/**
 * SUMMARY: constant variables for react router
 *
 *
 *
 */

export const SIGN_IN = '/sign_in';
export const PASSWORD_FORGET = '/pw-forget';
export const HOME = '/home';
export const EXPLORE = '/';
export const APPOINTMENTS = '/appointments';
export const MOBILE_MORE = '/more';

export const components = [
  {
    path: EXPLORE,
    component: Dashboard,
    exact: true,
  },
  {
    path: MOBILE_MORE,
    component: MobileMore,
    exact: true,
  },
  {
    path: '/saved-searches',
    component: MatchingDashboard,
    exact: true,
  },
  // {
  //   path: APPOINTMENTS,
  //   component: Appointments,
  //   exact: true,
  // },
  {
    path: '/pw-forget',
    component: PasswordForgetPage,
    exact: true,
  },
];
