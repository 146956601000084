import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { PasswordForgetLink } from './PasswordForget';
import { auth } from 'path-firebase';
import * as routes from '../constants/routes';
import './SignIn.css';

//images
import logo from '../images/lease-up-logo.gif';

/* Material UI */
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useOktaAuth } from '@okta/okta-react';

const SignInPage = ({ ...args }) => {
  // const { auth, history, updateRegion } = args; //deleted auth

  // if (auth && auth.email !== null) {
  //   history.push(routes.EXPLORE);
  // }
  const { oktaAuth, authState } = useOktaAuth();
  const login = async () =>
    oktaAuth.signInWithRedirect({ originalUri: window.location.href });
  const logout = async () => oktaAuth.signOut();

  console.log(authState);

  return (
    <div className="SignIn-Card">
      <div className="SignIn">
        <Grid container style={{ width: '100%' }}>
          <Grid item>
            <div>
              <Typography variant="h3">
                <img src={logo} style={{ width: '50%' }} alt="..." />
                Welcome To LeaseUp!
              </Typography>
            </div>
            <br />
            {/* <SignInForm history={history} updateRegion={updateRegion} /> */}
            {authState?.isAuthenticated ? (
              <button onClick={logout}>Logout</button>
            ) : (
              <button onClick={login}>Login</button>
            )}
            <br />
            <PasswordForgetLink />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  loginText: 'Sign In',
  region: 'la',
};

const SignInButtonStyle = {
  color: 'white',
  backgroundColor: '#0DB14B',
  borderRadius: '9999px',
};

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.props.updateRegion(this.state.region);
  }

  onSubmit = event => {
    const { email, password } = this.state;
    const { history } = this.props;
    this.setState({
      loginText: 'Signing In...',
    });
    // oktaAuth.token
    // .getWithRedirect({
    //   responseType: ['token', 'id_token'],
    //   state: 'defaultrandomstring',
    // })
    // .catch(err => console.error(err))
    // auth
    //   .doSignInWithEmailAndPassword(email, password)
    //   .then(() => {
    //     this.setState(() => ({ ...INITIAL_STATE }));

    //     history.push({ pathname: routes.EXPLORE });
    //   })
    //   .catch(error => {
    //     this.setState({ loginText: 'Sign In' });
    //     this.setState(byPropKey('error', error));
    //   });
    event.preventDefault();
  };

  handleChange = event => {
    this.setState(
      {
        region: event.target.value,
      },
      () => this.props.updateRegion(this.state.region)
    );
  };

  render() {
    const { email, password, error, loginText } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit} style={{ display: 'grid', gap: '15px' }}>
        <Input
          value={email}
          onChange={event =>
            this.setState(byPropKey('email', event.target.value))
          }
          type="text"
          placeholder="Email Address"
        />
        <Input
          value={password}
          onChange={event =>
            this.setState(byPropKey('password', event.target.value))
          }
          type="password"
          placeholder="Password"
        />
        <Select value={this.state.region} onChange={this.handleChange} native>
          <option value="la">Los Angeles</option>
          <option value="sb">Santa Barbara</option>
        </Select>
        <Button
          disable={isInvalid.toString()}
          type="submit"
          style={{ ...SignInButtonStyle }}>
          {loginText}
        </Button>
        {error && <p> {error.message}</p>}
      </form>
    );
  }
}

export default withRouter(SignInPage);
export { SignInForm };
