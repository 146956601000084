import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth } from 'path-firebase';
import './PasswordForget.css';

/* Material UI */
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = {
  background: {
    height: '100vh',
    width: '100%',
    background: 'linear-gradient(to bottom, #0099ff 0%, #6699ff 99%)',
  },
};

const PasswordForgetPage = () => (
  <div className="PasswordForgetCard" style={styles.background}>
    <div className="PasswordForget">
      <Typography
        variant="display1"
        style={{ width: '100%', textAlign: 'left' }}>
        Forgot Password
      </Typography>
      <br />
      <Grid
        container
        style={{ width: '100%', marginTop: '30px', display: 'block' }}>
        <Grid item>
          <PasswordForgetForm />
          <br />
          <Link to="/saved-searches" style={{ textDecoration: 'none' }}>
            <Button>Back To Login Page</Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  </div>
);

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    event.preventDefault();
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <form onSubmit={this.onSubmit} style={{ display: 'grid' }}>
        <Input
          value={this.state.email}
          onChange={event =>
            this.setState(byPropKey('email', event.target.value))
          }
          type="text"
          placeholder="Email Address"
        />
        <br />
        <Button
          disabled={isInvalid}
          onClick={() =>
            alert(
              'Please follow the link sent to your email to reset your password.'
            )
          }
          type="submit"
          style={{ backgroundColor: '#4caf50', color: 'white' }}>
          Reset Password
        </Button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <Link to="/pw-forget" style={{ textDecoration: 'none' }}>
    <Button>Forgot Password?</Button>
  </Link>
);

export default PasswordForgetPage;
export { PasswordForgetForm, PasswordForgetLink };
