import React from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import { firebase } from 'path-firebase';
import * as routes from '../constants/routes';
import axios from 'axios';
import { findApiUrl } from '../config/env';

/**
 *
 * Summary: You pass either the passed componets or nothing.
 *
 * ComponentDidMount Summary: uses the Firebase listener to trigger
 * a callback function in case the authenticated user object changes.
 * Every time the authUser changes, we check to see if it is not null.
 *
 */

const withAuthorization = Component => {
  class withAuthorization extends React.Component {
    async componentDidMount() {
      // // This is set by the mobile application when access the web view
      // const idToken =
      //   window.idToken ||
      //   (window.location.search.split('&')[0] &&
      //     window.location.search.split('&')[0].split('=')[1]);
      // if (idToken) {
      //   axios.interceptors.request.use(function (config) {
      //     config.headers.Authorization = `Bearer ${idToken}`;
      //     return config;
      //   });
      //   await axios.get(`${findApiUrl()}/custom-token`).then(({ data }) => {
      //     firebase.auth.signInWithCustomToken(data);
      //   });
      //   return;
      // }
    }
    render() {
      const { region } = this.props;
      return (
        // <AuthUserContext.Consumer>
        //   {authUser =>
        //     authUser ? (
        <Component region={region} email={'matt@verticalchange.com'} />
        //     ) : null
        //   }
        // </AuthUserContext.Consumer>
      );
    }
  }
  return withRouter(withAuthorization);
};

export default withAuthorization;
