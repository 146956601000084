import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { Typography } from '@material-ui/core';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

// import firebase from 'firebase/app';
import { getElasticCredentials } from 'config/env';
import * as routes from '../constants/routes';
import { firebase } from 'path-firebase';
import FB from 'path-firebase/firebase';
import SignIn from '../Components/SignIn';
import withAuthentication from '../Components/withAuthentication';
import withClearCache from './ClearCache';
import theme from '../Theme';
import { Redirect } from 'react-router-dom';
import { findApiUrl } from '../config/env';

import './App.css';
import MatchingDashboard from 'Components/Matching/MatchingDashboard';
import Dashboard from '../Dashboard/Dashboard';
const oktaAuth = new OktaAuth({
  issuer: 'https://epath.okta.com',
  clientId: '0oag9951eebthA5tg697',
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});

const ErrorComponent = props => {
  console.log({ props });
  return <pre>{JSON.stringify(props)}</pre>;
};

const MAP_KEY = process.env.REACT_APP_GOOGLE_API_K;
const OPENSEARCH_INDEX = process.env.OPENSEARCH_INDEX;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // authUser: null,
      region: 'la',
    };
    this.isNativeApp = window.ReactNativeWebView;
  }

  componentDidMount() {
    // firebase.auth.onAuthStateChanged(authUser => {
    //   const idToken =
    //     window.idToken ||
    //     (window.location.search.split('&')[0] &&
    //       window.location.search.split('&')[0].split('=')[1]);
    //   if (idToken) {
    //     axios.interceptors.request.use(function (config) {
    //       config.headers.Authorization = `Bearer ${idToken}`;
    //       return config;
    //     });
    //     axios.get(`${findApiUrl()}/custom-token`).then(({ data }) => {
    //       firebase.auth.signInWithCustomToken(data);
    //     });
    //     return;
    //   }
    //   if (authUser) {
    //     this.setState({ authUser });
    //   } else {
    //     this.setState({ authUser: null });
    //     console.log('REROUTE');
    //     console.log(window.location.pathname);
    //     if (window.location.pathname !== '/') {
    //       window.location = '/';
    //     }
    //   }
    // });
  }

  updateRegion = region => {
    this.setState({
      ...this.state,
      region,
    });
  };

  render() {
    // if (!this.state.authUser && window.location.path === '') {
    //   return (
    //     <Router>
    //       <Redirect to="/" />
    //     </Router>
    //   );
    // }
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ReactiveBase
            app={OPENSEARCH_INDEX || 'listings--0002-add-index-sort'}
            credentials={`${getElasticCredentials()}`}
            url="https://search-tenant-data-f7bm7cpqdpcux7ntn33sccpnym.us-west-2.es.amazonaws.com"
            mapKey={MAP_KEY}
            style={{
              backgroundColor: '#FFF',
            }}
            theme={{
              typography: {
                fontFamily: 'Open Sans, sans-serif',
              },
              colors: {
                textColor: '#5C5C5C',
                backgroundColor: '#FFF',
              },
            }}>
            {this.isNativeApp ? null : (
              <div className="brand">
                <svg
                  className="h-9 md:h-14 w-auto"
                  height="36"
                  fill="blue"
                  viewBox="0 0 58 70"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29 51.57c-12.509 0-22.685-10.154-22.685-22.636C6.315 16.454 16.491 6.302 29 6.302c12.508 0 22.685 10.153 22.685 22.633 0 12.482-10.177 22.637-22.685 22.637zm22.734-4.665A29.004 29.004 0 0058 28.935C58 12.98 44.992 0 29 0 13.01 0 0 12.98 0 28.934c0 6.494 2.109 12.63 6.097 17.75 1.408 1.813 2.887 3.309 4.535 4.934L29.053 70l16.472-16.445c2.295-2.268 4.277-4.228 6.209-6.65z"
                    fill="#ffffff"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 41h19V31H20v10z"
                    fill="#ffffff"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30 12l17 13.24V35L30 21.838V12z"
                    fill="#ffffff"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30 12v10.025L13 35v-9.51L30 12z"
                    fill="#ffffff"></path>
                </svg>
                <Typography
                  style={{ color: 'white', marginLeft: '0.5rem' }}
                  variant="h4">
                  LeaseUp
                </Typography>
              </div>
            )}
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={(_oktaAuth, originalUri) => {
                window.location = originalUri || '/';
              }}>
              <Switch>
                <SecureRoute
                  path={'/explore'}
                  render={props => {
                    console.log({ props });
                    return <Dashboard {...props} />;
                  }}
                />
                <SecureRoute
                  path={'/saved-searches'}
                  render={props => {
                    console.log({ props });
                    return <MatchingDashboard {...props} />;
                  }}
                />
                {/* {routes.components.map((route, i) => (
                  <SecureRoute
                    key={i}
                    path={route.path}
                    render={props => {
                      console.log({ route })
                      console.log({ props })
                      return (
                        <route.component
                          {...props}
                          region={this.state.region}
                        />
                      );
                    }}
                  />
                ))} */}
                <Route
                  path="/login/callback"
                  render={props => {
                    return (
                      <LoginCallback
                        {...props}
                        errorComponent={ErrorComponent}
                      />
                    );
                  }}
                />
                <Route
                  path={routes.SIGN_IN}
                  render={props => {
                    return (
                      <SignIn {...props} updateRegion={this.updateRegion} />
                    );
                  }}
                />
                <Route
                  path={'/'}
                  render={props => {
                    props.history.push('/explore');
                  }}
                />
              </Switch>
            </Security>
          </ReactiveBase>
        </ThemeProvider>
      </Router>
    );
  }
}

export default withAuthentication(withClearCache(App));
