import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import marker from '../images/lease-up-homepoint.gif';
import { mapOptions } from 'constants/index';

const Map = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(({ unitCoordinates }) => {
  const styles = {
    frame: {
      maxHeight: '100%',
    },
    unitDetailMap: {
      height: '100%',
    },
  };

  let defaultCenter = { lat: 34.0522, lng: -118.2437 };

  if (unitCoordinates.length) {
    defaultCenter.lat = unitCoordinates[0];
    defaultCenter.lng = unitCoordinates[1];
  }

  return (
    <div className="unitDetailMap" style={styles.unitDetailMap}>
      <div className="frame" style={styles.frame}>
        <GoogleMap
          defaultZoom={11}
          defaultCenter={defaultCenter}
          options={mapOptions}>
          <Marker
            key={0}
            position={{ lat: defaultCenter.lat, lng: defaultCenter.lng }}
            options={{
              icon: { url: marker, scaledSize: { width: 45, height: 45 } },
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
});

Map.propTypes = {
  unitCoordinates: PropTypes.array,
};

export default Map;
