import * as React from 'react';
import { withCookies } from 'react-cookie/cjs';
import { Button, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link as RouterLink } from 'react-router-dom';

import withAuthorization from 'Components/withAuthorization';
import { Nav } from 'Components/Nav';

import { auth } from 'path-firebase';

import styles from './styles.module.scss';

const MobileMore = ({ email }) => {
  return (
    <>
      <div className={styles['content']}>
        <Typography variant="h3">LeaseUp</Typography>
        <Typography variant="subtitle1">
          Help Bring our Neighbors home
        </Typography>
        <Button
          to=""
          color="primary"
          classes={{ root: styles['mobile-sign-out-btn'] }}
          variant="contained"
          component={RouterLink}
          onClick={auth.doSignOut}
          startIcon={<ExitToAppIcon />}>
          Sign Out
        </Button>
        <Typography>© 2022 LeaseUp (PATH)</Typography>
      </div>
      <Nav />
    </>
  );
};

export default withAuthorization(withCookies(MobileMore));
