import React from 'react';
import ReactiveGoogleMap from '@appbaseio/reactivemaps/lib/components/result/ReactiveGoogleMap';
import marker from 'images/lease-up-homepoint.gif';
import Loader from 'Components/Loader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewDetails from 'Components/ViewDetails';
import { mapOptions } from 'constants/index';

export const UnitMap = ({
  mapHeight = 'calc(100vh - 290px)',
  renderResults,
  paginationLimit,
  savedQuery,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const isNativeApp = window.ReactNativeWebView;
  const [unit, setUnit] = React.useState({});
  const [viewUnit, setViewUnit] = React.useState(false);

  const onPopoverClick = item => {
    setUnit(item);
    if (isMobile && isNativeApp) {
      setViewUnit(false);
      window.ReactNativeWebView.postMessage(JSON.stringify({ ...item }));
    } else {
      setViewUnit(true);
    }
    return <div>{item.name}</div>;
  };

  return (
    <>
      <ViewDetails
        email={unit?.unit_contact_email__c}
        unitDetails={unit}
        unitCoordinates={[unit.geocodes?.lat, unit.geocodes?.lng]}
        savedQueryId={''}
        criteriaAnswers={{}}
        setShowModal={setViewUnit}
        showModal={viewUnit}
      />
      <ReactiveGoogleMap
        autoCenter={true}
        autoClosePopover
        componentId="SearchResultMap"
        dataField="geocodes"
        defaultCenter={{ lat: 34.077521, lng: -118.288383 }}
        defaultMapStyle="Standard"
        defaultQuery={() => ({
          query: { match_all: {} },
          sort: {
            date_modified__c: {
              order: 'desc',
            },
          },
        })}
        markerProps={{ onMouseOver: () => {} }}
        onError={error => <div>{JSON.stringify(error)}</div>}
        onPopoverClick={onPopoverClick}
        mapOptions={mapOptions}
        loader={<Loader />}
        pagination={true}
        react={{
          and: [
            'BedRoom',
            'HiddenUnitsToggle',
            'Location',
            'RentalFeatures',
            'RentalPrice',
            'ProgramInformation',
          ],
        }}
        renderAllData={(
          hits,
          loadMore,
          renderMap,
          renderPagination,
          triggerClickAnalytics,
          { resultStats: { numberOfResults } }
        ) => {
          return (
            <div className="results-wrapper">
              <div className="results">
                {renderResults({
                  hits,
                  renderPagination,
                  numberOfResults,
                })}
              </div>
              <div className="results-map map-container">{renderMap()}</div>
            </div>
          );
        }}
        renderData={() => ({
          custom: (
            <img alt="Unit map marker" src={marker} width="35" height="35" />
          ),
        })}
        renderError={error => <pre>{JSON.stringify(error)}</pre>}
        size={paginationLimit || 4}
        showMarkerClusters={false}
        showSearchAsMove={false}
        style={{
          height: isMobile ? 'calc(100vh - 210px) ' : mapHeight,
        }}
        {...props}
      />
    </>
  );
};
