import firebase from 'firebase/app';
import 'firebase/auth/dist/index.cjs';
import 'firebase/database/dist/index.cjs';

const config = {
  apiKey: 'AIzaSyCk914e_eQ-dVJQoh7s8h9F2owNVGCozQ8',
  authDomain: process.env.REACT_APP_firebase_AuthDomain,
  databaseURL: process.env.REACT_APP_firebase_databaseURL,
  projectId: process.env.REACT_APP_firebase_projectId,
  storageBucket: process.env.REACT_APP_firebase_storageBucket,
  messagingSenderId: process.env.REACT_APP_firebase_messagingSenderId,
};

firebase.initializeApp(config);

const auth = firebase.auth();
export { auth };
export default firebase;
