import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewDetails from 'Components/ViewDetails';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import MediaImage from '../MediaImage';
import HideUnitIcon from '../HideUnitIcon';
import HideUnitModal from '../HideUnitModal';

// long transform to USD without fraction
const transformToUSD = num => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};

const useStyles = makeStyles(theme => ({
  root: {
    border: '0.2px solid rgba(0, 0, 0, 0.23)',
    overflow: 'visible',
    position: 'relative',
  },
  h6: {
    color: 'rgb(137, 40, 144)',
  },
  media: {
    width: '100%',
  },
}));

const mkBedroomBath = br => ba => {
  const mkPlural = Number(br) ? ` bedroom${br > 1 ? 's' : ''}` : '';
  return `${br}${mkPlural} | ${ba} ba`;
};

const ResultCard = props => {
  const {
    result: {
      unit_contact_email__c,
      account_status__c,
      account_rent__c,
      bedrooms__c,
      bathrooms__c,
      date_modified__c,
      id,
      geocodes,
      billingaddress = {},
      recordtype = {},
    },
    criteriaAnswers,
    savedQueryId,
    savedQueryName,
    postHiddenUnit,
    hiddenUnits,
  } = props;
  const classes = useStyles();
  const [viewDetails, setViewDetails] = React.useState(false);
  const [viewHideUnit, setViewHideUnit] = React.useState(false);
  const hidden = hiddenUnits.find(unit => unit.unit_id === id);
  const isMobile =
    useMediaQuery('(max-width: 900px)') && window.ReactNativeWebView;

  const handleView = () => {
    if (isMobile) {
      setViewDetails(false);
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ ...props.result, savedQueryName })
      );
    } else {
      setViewDetails(true);
    }
  };

  return (
    <Card
      className="search-result-card"
      classes={{ root: classes.root }}
      elevation={0}>
      <ViewDetails
        email={unit_contact_email__c}
        unitDetails={props.result}
        unitCoordinates={[geocodes?.lat, geocodes?.lng]}
        criteriaAnswers={criteriaAnswers}
        savedQueryId={savedQueryId}
        setShowModal={setViewDetails}
        showModal={viewDetails}
        postHiddenUnit={postHiddenUnit}
        renderHideUnitIcon={() => {
          return savedQueryId ? (
            <HideUnitIcon
              hidden={hidden}
              setViewHideUnit={setViewHideUnit}
              viewDetails
              viewHideUnit={viewHideUnit}
            />
          ) : null;
        }}
      />
      <HideUnitModal
        hidden={hidden}
        setOpen={setViewHideUnit}
        open={viewHideUnit}
        name={savedQueryName}
        postHiddenUnit={postHiddenUnit}
        unitId={id}
      />
      {new Date(date_modified__c) >
      new Date(new Date().getTime() - 72 * 60 * 60 * 1000) ? (
        <Chip label="New" className="new-tag" />
      ) : null}
      <CardMedia
        classes={{ root: classes.media }}
        component={MediaImage}
        id={id}
        hidden={hidden}
        onClick={handleView}
        savedQueryId={savedQueryId}
        setViewHideUnit={setViewHideUnit}
        viewHideUnit={viewHideUnit}
      />
      <CardContent
        onClick={handleView}
        classes={{ root: 'hide-on-native-mobile' }}>
        {recordtype.Name !== 'Public Listing' ? (
          <CheckCircleIcon style={{ color: '#9d00ff', float: 'right' }} />
        ) : null}
        <Typography gutterBottom variant="subtitle1">
          {billingaddress?.street}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {billingaddress?.city}, {billingaddress?.state}{' '}
          {billingaddress?.postalCode}
        </Typography>
        <Typography variant="h6" classes={{ root: classes.h6 }}>
          {transformToUSD(account_rent__c)}
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          {mkBedroomBath(bedrooms__c)(bathrooms__c)}
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          Status: {account_status__c}
        </Typography>
        {hidden && (
          <Typography color="textSecondary" variant="body2">
            Reason Hidden: {hidden.reason}
          </Typography>
        )}
      </CardContent>
      <CardContent onClick={handleView} classes={{ root: 'mobile-only' }}>
        {recordtype.Name !== 'Public Listing' ? (
          <CheckCircleIcon style={{ color: '#9d00ff', float: 'right' }} />
        ) : null}
        <Typography variant="h6">{billingaddress?.street}</Typography>
        <div className="row">
          <Typography gutterBottom variant="subtitle1">
            {billingaddress?.city}, {billingaddress?.state}{' '}
            {billingaddress?.postalCode}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {transformToUSD(account_rent__c)}
          </Typography>
        </div>
        <div className="row">
          <Typography color="textSecondary" variant="body2">
            {mkBedroomBath(bedrooms__c)(bathrooms__c)}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Status: {account_status__c}
          </Typography>
          {hidden && (
            <Typography color="textSecondary" variant="body2">
              Reason Hidden: {hidden.reason}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ResultCard;
