import * as React from 'react';
import HideUnitIcon from '../HideUnitIcon';

import styles from '../style.module.scss';

const thumbnailWhenNoImage =
  'https://units-images.s3.amazonaws.com/others/Canva+-+no+image+available+text+on+blackboard+2.jpg';

const getImageURL = unitId =>
  `https://units-images.s3.amazonaws.com/thumbnail/${unitId}/thumbnail.jpeg`;

const MediaImage = ({
  className,
  id,
  hidden,
  onClick,
  savedQueryId,
  setViewHideUnit,
  viewHideUnit,
}) => {
  return (
    <span className={`${className} ${styles.media}`}>
      <img
        alt="Unit thumbnail"
        className={className}
        src={getImageURL(id)}
        onError={e => {
          e.target.src = thumbnailWhenNoImage;
          e.target.onerror = null;
        }}
        onClick={onClick}
      />
      {savedQueryId ? (
        <HideUnitIcon
          hidden={hidden}
          viewHideUnit={viewHideUnit}
          setViewHideUnit={setViewHideUnit}
          viewDetails={false}
        />
      ) : null}
    </span>
  );
};

export default MediaImage;
