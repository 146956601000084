import * as React from 'react';
import { toast } from 'react-toastify';

import { saveQuery } from 'Components/UnitFilters/index';
import ResultCard from './ResultCard';
import styles from './style.module.scss';

const ResultCarousel = ({
  className = '',
  currentSearchState,
  criteriaAnswers,
  data,
  hiddenUnits,
  savedQueryId,
  savedQuery,
  setLoading,
}) => {
  const postHiddenUnit = unit => (hideUnit = true) => {
    const newHiddenUnits = hideUnit
      ? [...hiddenUnits, unit]
      : hiddenUnits.filter(u => u.unit_id !== unit.unit_id);
    const metaParams = {
      queryName: savedQuery.search.name,
      queryHmisId: savedQuery.search.hmis_id,
      clientSubsidy: savedQuery.search.client_subsidy,
      clientCaseManagementDurationInYears:
        savedQuery.search.client_case_management_duration_in_years,
      clientHasEvictionInTheLastSevenYears:
        savedQuery.search.client_has_eviction_in_the_last_7_years,
      clientIncomeInDollars: savedQuery.search.client_income_in_dollars,
      hiddenUnits: newHiddenUnits,
    };
    setLoading(true);
    saveQuery(currentSearchState, metaParams, savedQueryId)
      .then(data => {
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch(err => {
        console.error(err);
        toast.error(
          'There was an error, please contact support if the issue continues!'
        );
        setLoading(false);
      });
  };

  return (
    <div className={`${styles['result-carousel']} ${className}`}>
      {data.map(result => (
        <ResultCard
          key={result.id}
          result={result}
          data={data}
          criteriaAnswers={criteriaAnswers}
          savedQueryId={savedQueryId}
          savedQueryName={savedQuery?.search?.name}
          hiddenUnits={hiddenUnits}
          postHiddenUnit={postHiddenUnit}
        />
      ))}
    </div>
  );
};

export default ResultCarousel;
