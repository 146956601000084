import React, { Component } from 'react';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { withCookies } from 'react-cookie/cjs';
import axios from 'axios';
import './style-dashboard.scss';
import { getElasticCredentials } from '../config/env';
import ls from 'local-storage';

//React Context Provider
import { Provider } from '../containers/ReactContext';

//other components
import { Nav } from 'Components/Nav';
import withAuthorization from '../Components/withAuthorization';
import firebase, { auth } from 'path-firebase/firebase';
import TermsAndConditions from '../Components/TermsAndConditions';

//material-ui
import Typography from '@material-ui/core/Typography';

import { EMPTY_QUERY, UnitFilters } from 'Components/UnitFilters';
import { UnitMap } from 'Components/UnitMap';
import ResultCarousel from 'Components/Matching/ResultCarousel';
import { Container, Modal, Button } from '@material-ui/core';

const MAP_KEY = process.env.REACT_APP_GOOGLE_API_K;
const OPENSEARCH_INDEX = process.env.OPENSEARCH_INDEX;

const initialState = {
  isOpen: !Boolean(ls.get('hide-feature-announcement')),
  region: 'la',
  units: [],
  originalData: [],
  sortList: '',
  sortTypes: {},
  isMarkerShown: false,
  coordinates: [33.7817553, -118.2451816],
  showDetail: false,
  reset: false,
  favorites: [],
  email: '',
  feedback: '',
  isLoading: true,
  detailLoading: false,
  unitWalkScore: {},
  unitTransitScore: {},
  unitSearchStops: [],
  showAgreementTerms: false,
  personalizedRecs: [],
};

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.signal = axios.CancelToken.source();
  }

  async componentDidMount() {
    /**
     * Set Region Specific
     */
    const { region } = this.props;
    this.setState({
      ...this.state,
      region,
    });
    // const token = await auth.currentUser.getIdToken(true);
    // axios.interceptors.request.use(function (config) {
    //   config.headers.Authorization = `Bearer ${token}`;
    //   return config;
    // });
    /**
     * Retrieiving user information and save it to the state
     */
    // auth.onAuthStateChanged(async user => {
    //   if (user) {
    //     let email = user.email;
    //     const uid = user.uid;
    //     const emailArray = email.split('.');
    //     let usersEmail = emailArray[0];
    //     this.setState({ email, uid, firebaseUsername: usersEmail }); //trackLogin function tracks # of times it logs in.
    //   }
    // });
  }

  convertAddressToCoordinates = async units => {
    /**
     * Eliminated the need of using the Geocode library on the client side
     * The job is now done on the backend
     * Takes less time to run the coordinates because they are cached in Mongo
     */
    const coordinates = units.map((unit, i) => {
      const lat = unit['geocodes'] ? unit['geocodes']['lat'] : '';
      const lng = unit['geocodes'] ? unit['geocodes']['lng'] : '';
      if (lat && lng) {
        return [lat, lng];
      } else {
        return [];
      }
    });
    this.setState({ coordinates });
    return;
  };
  /**
   * Track whether the user has signed the agreement
   */
  trackTermsConditions = async () => {
    const usersEmail = this.state.firebaseUsername;
    const termsRef = firebase.database().ref(`terms-tracking/${usersEmail}`);
    return termsRef.once('value').then(snapshot => {
      if (!snapshot.val()) {
        this.setState({ showAgreementTerms: true });
      } else {
        this.setState({ showAgreementTerms: false });
      }
    });
  };
  /**
   * If the user agrees, it will update the firebase database.
   */
  setShowAgreementToTrue = () => {
    const usersEmail = this.state.firebaseUsername;
    let date = new Date().toJSON().slice(0, 10).toString();
    const termsRef = firebase.database().ref(`terms-tracking/${usersEmail}`);
    termsRef.push({
      usersEmail,
      date,
    });
    return;
  };
  /**
   * Track View History
   * Run as a setState callback in the sendDetails method
   */
  trackViewHistory = id => {
    const unit = { ...this.state.units[id] };
    const unitId = unit.Id;
    const usersEmail = this.state.firebaseUsername;
    const viewRef = firebase
      .database()
      .ref(`units-views/${unitId}/${usersEmail}`);
    const timeStamp = Date.now();
    viewRef.push(timeStamp);
    return;
  };

  setOpen = isOpen => {
    this.setState({ isOpen });
  };

  render() {
    const { email, showAgreementTerms, isOpen } = this.state;

    return (
      <ReactiveBase
        app={OPENSEARCH_INDEX || 'listings--0002-add-index-sort'}
        credentials={`${getElasticCredentials()}`}
        url="https://search-tenant-data-f7bm7cpqdpcux7ntn33sccpnym.us-west-2.es.amazonaws.com"
        mapKey={MAP_KEY}
        style={{
          backgroundColor: '#FFF',
        }}
        theme={{
          typography: {
            fontFamily: 'Open Sans, sans-serif',
          },
          colors: {
            textColor: '#5C5C5C',
            backgroundColor: '#FFF',
          },
        }}>
        <Provider value={this.state}>
          <Nav email={email} />
          <Container maxWidth="xl" classes={{ root: 'content' }}>
            <Modal
              open={isOpen}
              onClose={() => this.setState({ isOpen: false })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <div className="welcome-modal">
                <h1>LeaseUp New Release</h1>

                <p>
                  The latest release of LeaseUp has several distinctive new
                  features that will assist you in your work. 
                </p>

                <h3>Saved Searches</h3>
                <p>
                  Case Managers can create unique searches for particular
                  clients and save them for future reference.
                </p>
                <p>
                  When new units become available that match a saved search’s
                  criteria, they are added to the search and the Case Manager is
                  notified via email of the new units.
                </p>

                <h3>Appointments</h3>
                <p>
                  You can now schedule an appointment with a Housing Specialist
                  for viewing a property.
                </p>
                <p>
                  Simply select the property you would like to visit and choose
                  Schedule an Appointment.
                </p>

                <p>
                  We welcome feedback as you become familiar with this latest
                  release so that we can continue to improve it and facilitate
                  your work of service to those in need.
                </p>

                <Button
                  onClick={() => this.setState({ isOpen: false })}
                  className="get-started-btn">
                  Get Started!
                </Button>

                <label id="dont-show-again">
                  <input
                    type="checkbox"
                    onChange={() => {
                      const key = 'hide-feature-announcement';
                      ls.set(key, !Boolean(ls.get(key)));
                    }}
                  />
                  Don't show me this again
                </label>
              </div>
            </Modal>

            <div className="filters-row">
              <UnitFilters
                // Empty query TODO: Extract to constant
                savedQuery={EMPTY_QUERY}
                setCurrentSearchState={() => {}}
                currentSearchState={[{}]}
              />
              <div className="available-props-title">
                <Typography variant="h5">Available Properties</Typography>
              </div>
            </div>
            <UnitMap
              showMarkerClusters={true}
              // showSearchAsMove={true} FIXME Throws error
              // searchAsMove={true} FIXME Throws error
              paginationLimit={300}
              renderResults={({ hits, renderPagination }) => {
                return (
                  <>
                    <ResultCarousel data={hits} hiddenUnits={[]} />
                    {hits.length > 300 ? renderPagination() : null}
                  </>
                );
              }}
              mapHeight={'calc(100vh - 175px)'}
            />
          </Container>
          {showAgreementTerms && (
            <TermsAndConditions
              setShowAgreementToTrue={this.setShowAgreementToTrue}
            />
          )}
        </Provider>
      </ReactiveBase>
    );
  }
}

/**
 *
 * Summary: Wrap the Dashboard Component in a higher order component
 * and define the authorization condition.
 *
 */
export default withAuthorization(withCookies(Dashboard));
