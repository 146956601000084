import * as React from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie/cjs';
import {
  Button,
  Container,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import {
  DataSearch,
  ReactiveBase,
  ReactiveList,
} from '@appbaseio/reactivesearch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toast } from 'react-toastify';
import { useOktaAuth } from '@okta/okta-react';

import { firebase } from 'path-firebase';
import { findApiUrl } from 'config/env.js';
import Loader from 'Components/Loader';
import { Nav } from 'Components/Nav';
import WideCard from 'Components/WideCard';
import withAuthorization from 'Components/withAuthorization';
import { getElasticCredentials } from 'config/env';
import Modal from 'Components/Matching/Modal';
import ViewDetails from 'Components/ViewDetails';

import styles from './styles.module.scss';

const mkDate = dateTimeStr => format(parseISO(dateTimeStr, new Date()), 'PPP');

const mkTime = dateTimeStr => format(parseISO(dateTimeStr, new Date()), 'p');

const cancelReasons = [
  { label: 'Client Unreachable', value: 'Client Unreachable' },
  {
    label: 'Multiple Applications on different units',
    value: 'Multiple Applications on different units',
  },
  { label: 'Location undesired', value: 'Location undesired' },
  { label: 'Lack of transportation', value: 'Lack of transportation' },
  {
    label: 'Owner/property manager not responsive',
    value: 'Owner/property manager not responsive',
  },
  { label: 'Client gets ill', value: 'Client gets ill' },
  { label: 'Case manager unreachable', value: 'Case manager unreachable' },
];

const Appointments = ({ email }) => {
  const [selectedSlots, setSelectedSlots] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [userId, setUserId] = React.useState('');
  const [viewConfirmation, setViewConfirmation] = React.useState(false);
  const [ids, setIds] = React.useState({});
  const [appt, setAppt] = React.useState({});
  const [cancelReason, setCancelReason] = React.useState('');
  const [unit, setUnit] = React.useState({});
  const [viewDetails, setViewDetails] = React.useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const isMobile =
    useMediaQuery('(max-width: 900px)') && window.ReactNativeWebView;
  React.useEffect(() => {
    setUserId(authState.accessToken.claims.uid);
    axios.interceptors.request.use(function (config) {
      config.headers['x-jwt'] = authState.accessToken.accessToken;
      return config;
    });
    axios
      .get(`${findApiUrl()}/saved-appointments`, {
        params: {
          userId: authState.accessToken.claims.uid,
          email: authState.accessToken.claims.sub,
        },
      })
      .then(({ data }) => setSelectedSlots(data))
      .then(() => setLoading(false))
      .catch(err => {
        if (err.response?.status === 401) {
          window.location = '/';
        }
      });
  }, []);

  const cancelAppt = ({ selected_slot_id, _id }) => e => {
    // setLoading(true);
    // setViewConfirmation(false);
    //     axiox
    //       .put(`${findApiUrl()}/cancel-reserved-slot`, {
    //         headers: {
    //         },
    //         data: { _id, selected_slot_id, cancel_reason: cancelReason },
    //       })
    //       .then(({ data }) => {
    //         setLoading(false);
    //         setViewConfirmation(false);
    //         setCancelReason('');
    //       })
    //   .catch(err => {
    //     console.error(err);
    //     setLoading(false);
    //     setViewConfirmation(false);
    //     setCancelReason('');
    //   });
  };

  const handleCancel = ({
    selected_slot_id,
    _id,
    start_date_time,
    end_date_time,
    unit_name,
    housing_specialist_name,
    name,
  }) => e => {
    setViewConfirmation(true);
    setIds({ selected_slot_id, _id });
    setAppt({
      date: mkDate(start_date_time),
      time: `${mkTime(start_date_time)} - ${mkTime(end_date_time)}`,
      housing_specialist_name,
      name,
      unit_name,
    });
  };

  const fetchUnit = unitId => () => {
    // const currentUser = firebase.auth.currentUser;
    // currentUser
    //   .getIdToken(true)
    //   .then(token => {
    //     axios.interceptors.request.use(function (config) {
    //       config.headers.Authorization = `Bearer ${token}`;
    //       return config;
    //     });
    //   })
    //   .then(() => {
    //     return axios.get(`${findApiUrl()}/unit/${unitId}`);
    //   })
    //   .then(({ data }) => {
    //     if (data.length) {
    //       setUnit(data[0]._source);
    //       if (isMobile) {
    //         setViewDetails(false);
    //         window.ReactNativeWebView.postMessage(
    //           JSON.stringify({ ...data[0]._source })
    //         );
    //       } else {
    //         setViewDetails(true);
    //       }
    //       return;
    //     }
    //     toast.error('This unit is no longer available.');
    //     throw new Error('No units match the requested id');
    //   })
    //   .catch(err => {
    //     console.error({ err });
    //     if (err.response?.status === 401) {
    //       console.log('sign out here');
    //     }
    //   });
  };

  return (
    <div>
      <Nav email={email} />
      <Container maxWidth="lg" classes={{ root: styles.content }}>
        <Typography
          align="center"
          variant="h4"
          classes={{ root: 'mobile-only' }}>
          Review My Appointments
        </Typography>
        <Typography classes={{ root: 'hide-on-native-mobile' }} variant="h4">
          My appointments
        </Typography>
        <Modal
          setOpen={setViewConfirmation}
          open={viewConfirmation}
          title="Cancel your appointment"
          btnOne="No, don't cancel"
          btnTwo="Yes, cancel appointment"
          onCommit={cancelAppt(ids)}
          commitEnabled={Boolean(cancelReason)}
          containerClass={styles.center}
          contentClass={styles.modal}>
          <div className={`${styles.col} ${styles['cancel-dialog']}`}>
            <div>
              <Typography className="date" variant="h6">
                {appt.date}
              </Typography>
              <div className="time">
                <Typography variant="h6">{appt.time}</Typography>
              </div>
            </div>
            <div>
              <Typography variant="subtitle2">
                <span className="bold-subtitle">Housing Specialist:</span>
                {appt.housing_specialist_name}
              </Typography>
              <Typography variant="subtitle2">
                <span className="bold-subtitle">Saved Search:</span>
                {appt.name ? appt.name : 'N/A'}
              </Typography>
            </div>
            <Typography variant="subtitle1">{appt.unit_name}</Typography>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="cancel-reason-label" margin="dense">
                Cancel Reason
              </InputLabel>
              <Select
                labelId="cancel-reason-label"
                id="cancel-reason"
                label="Cancel Reason"
                margin="dense"
                value={cancelReason}
                onChange={({ target: { value } }) => setCancelReason(value)}>
                {cancelReasons.map(({ value, label }, idx) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Modal>
        <ViewDetails
          email={unit.unit_contact_email__c}
          unitDetails={unit}
          unitCoordinates={[unit.geocodes?.lat, unit.geocodes?.lng]}
          criteriaAnswers={appt}
          savedQueryId={ids._id}
          setShowModal={setViewDetails}
          showModal={viewDetails}
        />
        {loading ? (
          <Loader />
        ) : (
          <ReactiveBase
            app="saved-appointments--0001"
            credentials={`${getElasticCredentials()}`}
            url="https://search-tenant-data-f7bm7cpqdpcux7ntn33sccpnym.us-west-2.es.amazonaws.com">
            <div className="data-search">
              <DataSearch
                componentId="AppointmentSearch"
                dataField={[
                  'housing_specialist_name',
                  'name',
                  'address.postalCode',
                  'address.street',
                  'unit_name',
                ]}
                innerClass={{ input: 'data-search-input' }}
                placeholder="Type a Housing Specialist name, Address, or name of Saved Search"
                showIcon={false}
                debounce={250}
              />
            </div>
            <div className={styles['appointment-list']}>
              <ReactiveList
                componentId="AppointmentResult"
                dataField="start_date_time"
                defaultQuery={function (value, props) {
                  return {
                    query: {
                      bool: {
                        must: [
                          {
                            match: {
                              user_id: userId,
                            },
                          },
                          {
                            terms: {
                              'selected_slot_id.keyword': Object.keys(
                                selectedSlots
                              ),
                            },
                          },
                        ],
                      },
                    },
                  };
                }}
                loader={<Loader />}
                react={{
                  and: ['AppointmentSearch'],
                }}
                render={({ loading, error, data }) =>
                  data.map((appt, idx) => {
                    const {
                      start_date_time,
                      end_date_time,
                      unit_name,
                      unit_id,
                      housing_specialist_name,
                      name,
                      selected_slot_id,
                    } = appt;
                    return (
                      <WideCard
                        key={idx}
                        lastCard={data.length === idx + 1}
                        idx={idx}
                        length={data.length}>
                        <div className={styles.row}>
                          <div className={`${styles.col} ${styles.details}`}>
                            <Typography className="date" variant="h5">
                              {mkDate(start_date_time)}
                            </Typography>
                            <div className="time">
                              <Typography variant="h6">
                                {mkTime(start_date_time)} -{' '}
                                {mkTime(end_date_time)}
                              </Typography>
                            </div>
                            <div className="housing-specialist">
                              <Typography variant="subtitle2">
                                <span className="bold-subtitle">
                                  Housing Specialist:
                                </span>
                                {housing_specialist_name}
                              </Typography>
                            </div>
                            <div className="client">
                              <Typography variant="subtitle2">
                                <span className="bold-subtitle">
                                  Saved Search:
                                </span>
                                {name || 'N/A'}
                              </Typography>
                            </div>
                            <div className="status">
                              {selectedSlots[selected_slot_id] ===
                              'Unavailable' ? (
                                ''
                              ) : (
                                <Typography variant="subtitle2">
                                  <span className="bold-subtitle">Status</span>{' '}
                                  {selectedSlots[selected_slot_id]}
                                </Typography>
                              )}
                            </div>
                          </div>
                          <div
                            className={`${styles.col} ${styles['unit-name']}`}>
                            <div onClick={fetchUnit(unit_id)}>
                              <Button variant="outlined" color="default">
                                {unit_name}
                              </Button>
                            </div>
                          </div>
                          <div className={`${styles.col} ${styles.actions}`}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={handleCancel(appt)}>
                              Cancel Appointment
                            </Button>
                          </div>
                        </div>
                      </WideCard>
                    );
                  })
                }
                renderNoResults={() => 'No appointments found!'}
                sortBy={'asc'}
                showResultStats={false}
              />
            </div>
          </ReactiveBase>
        )}
      </Container>
    </div>
  );
};

export default withAuthorization(withCookies(Appointments));
