import React from 'react';
import { Typography } from '@material-ui/core';

export const EmptyState = ({ title, description, children }) => (
  <div className="empty-state">
    <div className="nested-container">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      {children}
    </div>
  </div>
);
