import * as React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  title: {
    paddingBottom: '0',
  },
});

const Modal = ({
  setOpen,
  open,
  title,
  description = '',
  children,
  btnOne,
  btnTwo,
  onCommit,
  commitEnabled = true,
  containerClass = '',
  contentClass = '',
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const handleCommit = () => {
    onCommit();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ root: containerClass }}>
      <DialogTitle id="form-dialog-title" classes={{ root: classes.title }}>
        {title}
      </DialogTitle>
      {description && <DialogContentText>{description}</DialogContentText>}
      <DialogContent classes={{ root: `${classes.content} ${contentClass}` }}>
        {children}
      </DialogContent>
      <DialogActions>
        {btnOne ? (
          <Button onClick={handleClose} color="primary">
            {btnOne}
          </Button>
        ) : null}
        {btnTwo ? (
          <Button
            onClick={handleCommit}
            disabled={!commitEnabled}
            variant="contained"
            color="primary">
            {btnTwo}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
