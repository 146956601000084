import React from 'react';

//mateiral ui components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//firebase authentication
import { auth } from 'path-firebase';

class TermsAndConditions extends React.Component {
  state = {
    open: true,
    showHeader: true,
    showTerms: false,
    showAgree: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  agreeTerms = () => {
    //once agreed, the path_app_agreement is set to be true in cookies
    this.props.setShowAgreementToTrue();
    this.setState({ open: false });
  };

  showTerms = (action, type) => {
    if (action === 'show' && type === 'terms') {
      this.setState({
        showHeader: false,
        showTerms: true,
      });
    } else if (action === 'close') {
      this.setState({
        showHeader: true,
        showTerms: false,
        showAgree: false,
      });
    }
  };

  checkBox = () => {
    const inputBox = this.refs.checkBox;
    if (inputBox.checked) {
      this.setState({ showAgree: true });
    } else {
      this.setState({ showAgree: false });
    }
  };

  signOut = () => {
    this.setState({ open: false }, auth.doSignOut);
  };

  render() {
    const { showHeader, showTerms, showAgree } = this.state;

    return (
      <div>
        <Dialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {'Welcome to PATH’s Housing Partnership Website!'}
          </DialogTitle>
          <DialogContent>
            {showHeader && (
              <DialogContentText id="alert-dialog-description">
                As a condition for using our Housing Partnership website, PATH
                requires that you read and accept our Terms and Conditions,
                including the Landlord Standards of Care, which are available{' '}
                <span
                  id="terms-here-keyword"
                  onClick={() => this.showTerms('show', 'terms')}>
                  here
                </span>
              </DialogContentText>
            )}
            {showTerms && (
              <DialogContentText>
                Privacy, Security and Disclaimer Policy for Use of this Website
                <br />
                Welcome to our website! This website is owned, operated and
                maintained by PATH (herein “People Assisting the Homeless,”
                “PATH,” “we,” “us,” or “our”). Your privacy and the security of
                this website is important to us. To demonstrate our utmost
                commitment to your privacy and security of this website, we have
                provided this Privacy, Security and Disclaimer of Liability
                Policy and Term and Conditions of Use (collectively the “Policy”
                or “Terms and Conditions”) that outline and explain our online
                information processes, practices, policies and procedures. This
                Policy applies to all “personally identifiable information,” as
                well as any other media form, media channel, mobile website or
                other mobile application related, linked, or otherwise connected
                thereto (collectively, the “Website”). By accessing and using
                this Website, you hereby understand and agree that you have
                read, understand and hereby agree to be bound by all of the
                Terms and Conditions of this Policy that are outlined below. If
                you do not agree with any of the terms and conditions of this
                Policy, then you are hereby prohibited from entering and using
                this Website and must discontinue use immediately by closing
                your browser and exiting this Website. Terms and Conditions of
                this Policy The following Terms and Conditions apply to your
                access and use of this Website. Reservation of Rights. We
                reserve the right, in our sole discretion, to make changes or
                modifications to the Terms and Conditions of this Website at any
                time and/or for any reason. We will alert you about any changes
                by updating the “Last Updated” date of these Terms and
                Conditions, which you understand you waive any right to receive
                any specific notice of each such change. The “Last Updated” date
                is located at the end of these Terms and Conditions. It is your
                responsibility to periodically review these Terms and Conditions
                and to stay informed of any such updates. You will be subject
                to, and will be deemed to have been made aware of and to have
                accepted any changes in any revised Terms and Conditions by your
                continued accessing and use of the Website after the date such
                revised Terms and Conditions are posted. Provided you are
                eligible to use this Website, you are hereby granted a limited
                license to access and use the Website for purposes of
                downloading, uploading or printing any information or any
                portion thereof to which you have properly gained access solely
                for your personal, non-commercial use. We reserve all rights not
                expressly granted to you in and to the Website. These Terms of
                Use shall remain in full force and effect while you use the
                Website. Without limiting any other provision of these Terms and
                Conditions, we reserve the right to, in our sole discretion and
                without notice or liability, deny access to and use of the
                Website (including blocking certain IP addresses), to any person
                or corporation, for any reason or no reason, including but not
                limited to for breach of any representation, warranty or
                covenant contained in these terms of use or of any applicable
                law or regulation. In addition, we may terminate your use or
                participation in the Website or delete any account and/or any
                content or information that you, the user of this Website,
                posted, input, attached or otherwise uploaded to this Website,
                without notice or warning, at our sole discretion. User
                Requirements. This Website is intended for users who are at
                least 18 years of age. Persons under the age of 18 are not
                permitted to use, register or otherwise enter any information
                required by or otherwise requested by this Website, unless the
                express, written consent of a minor’s larent/legal guardian is
                provided to PATH. Copyright, Patent and Intellectual Property
                Rights. Unless otherwise indicated, the Website is our
                proprietary property and all source code, databases,
                functionality, software, website designs, audio, video, text,
                photographs, graphics, trademarks, service marks and logos
                contained in the Website are owned and/or controlled by us or
                licensed to us, and are protected by copyright, intellectual
                property and/or trademark laws of the United States, State of
                California, foreign jurisdictions, and international conventions
                that may apply. By using our Website, you hereby understand and
                agree that this Website and the information provided herein is
                for your information and personal use only. Unless otherwise
                provided for in this Website, no content or marks on this
                Website may be copied, reproduced, aggregated, republished,
                uploaded, posted, publicly displayed, encoded, translated,
                transmitted, distributed, sold, licensed, or otherwise exploited
                for any commercial or personal purpose whatsoever, without the
                express, written consent by PATH. User Representations. By using
                this Website, you represent and warrant the following:
                <br />
                <br /> • All registration information you submit will be true,
                accurate, current, and complete;
                <br /> • You will maintain the accuracy of such information and
                promptly update such registration information as necessary;
                <br />• You have the legal capacity and you agree to comply with
                these Terms and Conditions;
                <br /> • You are not a minor in the jurisdiction in which you
                reside;
                <br /> • You will not access the Website through automated or
                non-human means, whether through a bot, script or otherwise;
                <br /> • You will not use this Website for any illegal or
                unauthorized purpose; and
                <br /> • Your use of this Website will not violate any
                applicable Federal, State and local laws, rules or regulations.
                Note that if you provide any information that is untrue,
                inaccurate, not current, or incomplete, we have the right to
                suspend or terminate your account and/or refuse any and all
                current or future use of this Website (or any portion thereof).
                User Registration. You may be required to register with the
                Website. You agree to keep your password confidential and will
                be responsible for all use of your account and password. We
                reserve the right to remove, reclaim, or change a username you
                select if we determine, in our sole discretion, that such
                username is inappropriate, obscene, or otherwise objectionable.
                Prohibited Activities. You may not access or use this Website
                for any purpose other than that for which we make the Website
                available. As a visitor and user of this Website, you agree not
                to do any of the following:
                <br />
                <br />• Systematically retrieve data or other content from the
                Website to create or compile, directly or indirectly, a
                collection, compilation, database, or directory without written
                permission from us;
                <br />• Make any unauthorized use of the Website, including
                collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited
                email, or creating user accounts by automated means or under
                false pretenses; use a buying agent or purchasing agent to make
                transactions on the Website;
                <br />• Use the Website to advertise or offer to sell goods
                and/or services; circumvent, disable, or otherwise interfere
                with security-related features of the Website, including
                features that prevent or restrict use or copying of any content
                or enforce limitations on the use of the Website and/or the
                content contained herein; engage in unauthorized framing of or
                linking to the Website;
                <br /> • Trick defraud, or mislead us and/or other users,
                especially in any attempt to learn sensitive account information
                such as user passwords;
                <br /> • Make improper use of our support services or submit
                false reports of abuse or misconduct; interfere with, disrupt,
                or create an undue burden on the Website or the networks or
                services connected to the Website;
                <br /> • Attempt to impersonate another user or person or use
                the username of another user; use any information obtained from
                the Website in order to harass, abuse, or harm another person;
                <br /> • Decipher, decompile, disassemble, or reverse engineer
                any of the software comprising or in any way making up a part of
                the Website;
                <br /> • Copy or adapt the Website software, including but not
                limited to Flash, PHP, HTML, JavaScript, or other code;
                <br /> • Upload or transmit (or attempt to upload or to
                transmit) viruses, Trojan horses, or other material, including
                excessive use of capital letters and spamming (continuous
                posting of repetitive text), that interferes with any party’s
                uninterrupted use and enjoyment of the Website or modifies,
                impairs, disrupts, alters, or interferes with the use, features,
                functions, operation, or maintenance of the Website; and
                <br /> • Upload or transmit (or attempt to upload or to
                transmit) any material that acts as a passive or active
                information collection or transmission mechanism, including
                without limitation, clear graphics interchange formats (“gifs”),
                1×1 pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as “spyware” or “passive collection
                mechanisms” or “pcms”). Personally Identifiable Information.
                Personally identifiable information is information that
                identifies a particular individual including, but not limited
                to, name, address, phone number, or e-mail address. PATH does
                not collect any personally identifiable information unless you
                voluntarily provide it to us by uploading, attaching or
                otherwise inputting such information as requested by this
                Website. We use personally identifiable information to answer
                your questions or provide you with the information you
                requested. We may also use your personally identifiable
                information to tailor our Website to you and for any other
                internal purpose. PATH does not share your personally
                identifiable information with any third parties without your
                permission, except: (1) where an applicable law, regulation or
                legal process requires it; (2) to investigate, prevent, or take
                action regarding fraud, threats of harm to others, or other
                illegal activities; and/or (3) to exercise our legal rights
                under this Policy and its Terms and Conditions or to defend
                against any legal or other similar or related claim. On
                occasion, we may share your contact information (i.e. name, job
                title, mailing address, phone number, fax number and email
                address) with grantee, funders and other organizations whose
                activities are, in our judgment, compatible with the goals,
                purposes and mission statement of PATH. If you do not wish to
                have your information shared in this way, please contact us.
                Please note that changes to your preferences may not be
                effective immediately and will only be effective prospectively.
                Aggregated Information (i.e. cookies). A cookie is a message
                that is sent to your computer from a web server and stored on
                your computer’s hard drive. An IP address is a number that is
                assigned to your computer automatically when you use the
                Internet. Most websites use cookies and log your IP address.
                When you visit our Website, we may use cookies and log your IP
                address. However, we do not link our cookies or your IP address
                to any of your personally identifiable information. We aggregate
                this information with that of other users and analyze it to
                improve the Website. We may share aggregated information with
                potential collaborators, advertisers, funders, content providers
                and customers, in connection with our services. However, this
                aggregated information does not include any of your personally
                identifiable information. No Absolute Safeguards. Although we
                strive to use all reasonable efforts to safeguard the privacy of
                all information input into, attached to or otherwise uploaded
                onto this Website, transmissions on the Internet cannot be made
                absolutely secure. Accordingly, we assume no responsibility or
                liability for disclosure of your personal or any other
                information (personally identifiable or otherwise) due to
                errors, viruses, malware or other malicious acts committed in
                transmission, unauthorized third-party access, or other causes
                beyond our reasonable control. User Data. We will maintain
                certain data that you transmit to the Website for the purpose of
                managing the performance of the Website, as well as data
                relating to your use of the Website. Although we perform regular
                routine backups of data, you are solely responsible for all data
                that you transmit or that relates to any activity you have
                undertaken using the Website. You agree that we shall have no
                liability to you for any loss or corruption of any such data,
                and you hereby waive any right of action against us arising from
                any such loss or corruption of such data. Linked Websites. This
                Website contains links to the websites of third-party
                organizations not affiliated with PATH. You understand and agree
                that PATH does not control the privacy, security and/or other
                practices/policies of such third parties. If you click on a
                link, the privacy, security and/or other practices/policies of
                such third party websites that you link to will govern the use
                of your information by that website’s operator/administrator. We
                assume no responsibility or liability for the actions committed
                by such third parties with respect to their use of your personal
                or other information. Accordingly, make sure you are aware of,
                and comfortable with, the privacy, security and/or other
                practices/policies of any third-party websites you link to.
                Correct/Update Personal Information.  If you would like to
                review, update, change or otherwise delete any information that
                we have collected from you, please contact us directly by e-mail
                at JenniferLee@epath.org. Errors/Corrections. There may be
                information on the Website that contains typographical errors,
                inaccuracies or omissions, including descriptions, availability,
                services, programs and/or other various or related types of
                information. As such, we reserve the right to correct any
                errors, inaccuracies, or omissions and to change and/or update
                such information at any time, without prior notice. Disclaimer.
                This Website is provided on an as-is and as-available basis. You
                agree as a user of this Website that your use and our services
                will be at your sole risk. To the fullest extent permitted by
                law, we disclaim any and all warranties, express or implied, in
                connection with the website and your use thereof, including,
                without limitation, the implied warranties of merchantability,
                fitness for a particular purpose and non-infringement.
                Limitation of Liability. In no event will PATH or our Board
                members, executives, directors, employees or other agents of
                PATH be liable to you or any third party for any direct,
                indirect, consequential, exemplary, incidental, special or
                punitive damages, including lost profit, lost revenue, loss of
                data or other damages arising from your use of the Website, even
                if we have been advised of the possibility of such damages.
                Indemnification. By using this Website, you agree to defend,
                indemnify, and hold us harmless, including our subsidiaries,
                affiliates, and all of our respective officers, agents,
                partners, and employees, from and against any loss, damage,
                liability, claim, or demand, including reasonable attorneys’
                fees and expenses, made by any third party due to or arising out
                of: (1) your contributions; (2) use of the Website; (3) breach
                of these Terms and Conditions; (4) any breach of your
                representations and warranties set forth in these Terms and
                Conditions; (5) your violation of the rights of a third party,
                including but not limited to intellectual property rights; or
                (6) any overt harmful act toward any other user of the Website
                with whom you connected via the Website. Notwithstanding the
                foregoing, we reserve the right, at your expense, to assume the
                exclusive defense and control of any matter for which you are
                required to indemnify us, and you agree to cooperate, at your
                expense, with our defense of such claims. We will use reasonable
                efforts to notify you of any such claim, action, or proceeding
                which is subject to this indemnification upon becoming aware of
                it. Governing Law.
                <br />
                <br />
                The Terms and Conditions of this Website and your use of the
                Website are governed by and construed in accordance with the
                laws of the State of California, which includes, but is not
                limited to any agreements made and entirely performed within the
                State of California, without regard to its conflict of law
                principles.
                <br />
                <br /> Last Updated on: <strong>November 3rd, 2018 </strong>
                PATH (a.k.a. People Assisting the Homeless) is a California
                non-profit public benefit corporation with its principle place
                of business at 340 North Madison Avenue, Los Angeles, California
                90004.
              </DialogContentText>
            )}
            {showTerms && (
              <DialogContentText id="landlord-care">
                <br />
                <strong>
                  User Responsibilities in Landlord Standards of Care
                </strong>
                <br />
                <br />
                1.) User will not share their login credentials with anyone,
                including other staff and program participants. <br />
                2.) User agrees to be present with the participant during the
                viewing, leasing and move-in process and ensure that the
                participant is briefed and understands important aspects of the
                lease. <br />
                3.) User agrees to make home visits as needed, but at a minimum
                of one per month to each tenant, and connect with the onsite or
                offsite Landlord to assess how the participant’s tenancy is
                progressing and to address any concerns or questions. <br />
                4.) User agrees to return all calls and address concerns from
                participating Landlords as promptly as possible, with a one
                business day standard for urgent matters and a three-business
                day standard for all others.
                <br />
                5.) User agrees they will ensure on-time payments are made to
                the Landlord. Payments must be made generally within five
                business days of all documents being approved (including
                security deposit, monthly rent, and all additional rental
                expenses for Landlords). Any delay must be promptly communicated
                to the Landlord. <br />
                6.) User must ensure Landlords understand that participation
                with the LA County CES entails providing tenancy to persons
                experiencing homelessness.
                <br />
                <br />
                <strong>
                  If User violates any of any of the above Landlord Standards of
                  Care, User will forfeit their login credentials for the
                  PATH-app website.
                </strong>
                <br />
                <br />
                <span style={{ color: '#C70039' }}>
                  <strong>
                    <input
                      id="checkBox"
                      ref="checkBox"
                      type="checkbox"
                      onClick={this.checkBox}
                    />
                    By clicking, I hereby understand, agree and will abide by
                    the above Terms and Conditions and Landlord Standards of
                    Care, and further.
                  </strong>
                </span>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {showTerms && (
              <Button
                onClick={() => this.showTerms('close')}
                color="primary"
                autoFocus>
                Back
              </Button>
            )}
            <Button onClick={this.signOut} color="primary">
              Disagree
            </Button>
            {
              <Button
                onClick={this.agreeTerms}
                color="primary"
                disabled={!showAgree}>
                Agree
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TermsAndConditions;
