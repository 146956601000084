import * as React from 'react';
import ReactLoading from 'react-loading';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const mobileProps = {
  type: 'spokes',
  color: '#E8ECF0',
  height: '36px',
  width: '36px',
};
const webProps = {
  type: 'spin',
  color: 'rgb(137, 040, 144)',
  height: '64px',
  width: '64px',
};

const Loader = () => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const props = isMobile ? mobileProps : webProps;
  return (
    <div className="content">
      <div className="empty-state loading-state">
        <ReactLoading {...props} />
        <p>Loading!</p>
      </div>
    </div>
  );
};

export default Loader;
