import React from 'react';
import { useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import ResultCarousel from '../ResultCarousel';
import { Button } from '@material-ui/core';

import {
  UnitFilters,
  ReactiveToggle,
  ResetFiltersButton,
} from 'Components/UnitFilters';
import { UnitMap } from 'Components/UnitMap';
import Loader from 'Components/Loader';

import styles from './style.module.scss';

const renderNoResults = savedQueryId => {
  return (
    <div className={styles['no-results']}>
      <Typography variant="h5">No Results Found!</Typography>
      <ResetFiltersButton className="" />
    </div>
  );
};

const mkHiddenUnitsQuery = hiddenUnits => {
  const mustNot = hiddenUnits.reduce((acc, { unit_id }) => {
    const match = {
      match: {
        id: unit_id,
      },
    };
    return acc.concat(match);
  }, []);
  return { query: { bool: { must_not: mustNot } } };
};

export const SavedSearchDetail = ({
  savedQueries,
  criteriaAnswers,
  handleLoading = () => {},
}) => {
  const { savedQueryId } = useParams();
  const [savedQuery, setSavedQuery] = React.useState({});
  const [currentSearchState, setCurrentSearchState] = React.useState({});
  const [hiddenUnitsQuery, setHiddenUnitsQuery] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [hiddenUnits, setHiddenUnits] = React.useState([]);

  React.useEffect(() => {
    if (savedQueries.length) {
      const found = savedQueries.find(q => q._id === savedQueryId);
      const sq = found?._source || found;
      const hiddenUnits = sq?.search?.hidden_units;
      if (hiddenUnits?.length) {
        const hiddenUnitsQuery = mkHiddenUnitsQuery(hiddenUnits);
        setHiddenUnitsQuery(hiddenUnitsQuery);
        setHiddenUnits(hiddenUnits);
      }
      setSavedQuery(sq);
      handleLoading(setLoading);
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(hiddenUnitsQuery).length) {
      setLoading(false);
    }
  }, [hiddenUnitsQuery]);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });

  const classes = useStyles();

  return (
    <>
      <div className="row">
        <div className="row">
          <Button
            className={classes.root}
            component={RouterLink}
            to="/saved-searches">
            <ArrowBackIosIcon fontSize="small" />
            Saved Searches
          </Button>
        </div>
      </div>
      {savedQuery?.search?.name ? (
        <div className="saved-search-info">
          <div className="saved-search-name-hmis-id">
            <Typography variant="h5">{savedQuery.search.name}</Typography>
            <Typography variant="subtitle2">
              HMIS: {savedQuery.search.hmis_id}
            </Typography>
          </div>
          <div className="matched-properties-title">
            <Typography variant="h5">Matched Properties</Typography>
            <ReactiveToggle
              hiddenUnits={hiddenUnits}
              hiddenUnitsQuery={hiddenUnitsQuery}
              mkHiddenUnitsQuery={mkHiddenUnitsQuery}
              savedQueryId={savedQueryId}
              setLoading={setLoading}
            />
          </div>
        </div>
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <UnitFilters
              criteriaAnswers={criteriaAnswers}
              currentSearchState={currentSearchState}
              savedQuery={savedQuery}
              queryId={savedQueryId}
              setCurrentSearchState={setCurrentSearchState}
            />
          </div>
          <div className="saved-search-result-list-container">
            <UnitMap
              paginationLimit={4}
              savedQuery={savedQuery}
              mapHeight={
                savedQueryId ? 'calc(100vh - 290px)' : 'calc(100vh - 230px)'
              }
              renderResults={({ hits, renderPagination, numberOfResults }) => {
                return numberOfResults ? (
                  <>
                    <ResultCarousel
                      currentSearchState={currentSearchState}
                      criteriaAnswers={savedQuery.search}
                      data={hits}
                      hiddenUnits={hiddenUnits}
                      savedQueryId={savedQueryId}
                      savedQuery={savedQuery}
                      setLoading={setLoading}
                    />
                    {numberOfResults > 4 ? renderPagination() : null}
                  </>
                ) : numberOfResults !== undefined ? (
                  renderNoResults(savedQueryId)
                ) : null;
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
