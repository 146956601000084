import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import axios from 'axios';
import { findApiUrl } from '../../config/env';
import ReactLoading from 'react-loading';
import './Carousel.style.css';

const apiURL = findApiUrl();

const initialState = {
  imagesLinks: [],
  unitId: '',
  imageLoading: true,
  wrapAround: false,
  containerClassName: 'carousel-container',
};

class CarouselContainer extends React.Component {
  constructor() {
    super();
    this.state = { ...initialState };
  }

  componentDidMount() {
    const unitId = this.props.unitDetailsId;
    this.setState({ unitId }, this.getImagesLinks);
  }

  getImagesLinks = () => {
    const { unitId } = this.state;
    axios
      .get(`${apiURL}/getImages/${unitId}`)
      .then(res => res.data)
      .then(imagesLinks => {
        const numberOfImages = imagesLinks.length;
        this.setState(
          { imagesLinks, numberOfImages, imageLoading: false },
          this.toggleAttribute('wrapAround', true)
        );
      })
      .catch(err => console.error(err));
  };

  toggleAttribute = (attribute, value) => {
    const { numberOfImages } = this.state;
    if (numberOfImages > 1) {
      this.setState({
        [attribute]: value,
      });
    }
  };

  render() {
    const { imagesLinks, imageLoading, wrapAround } = this.state;
    const images = imagesLinks.map((link, i) => {
      return {
        original: link,
        originalClass: `unitImage${i}`, //every image has a classname starting with unitImage${the index number}
      };
    });
    const containerClassName = images.length ? 'carousel-container' : 'hide';
    return (
      <div className={containerClassName}>
        {imageLoading ? (
          <ReactLoading
            id="image-bubble-spin"
            type="spinningBubbles"
            color="green"
            height={'20%'}
            width={'50%'}
          />
        ) : (
          <Carousel
            slidesToScroll={1}
            slidesPerPage={1}
            slideWidth={1}
            renderBottomCenterControls={null}
            framePadding={'15px'}
            heightMode={'current'}
            enableKeyboardControls
            wrapAround={wrapAround}>
            {images.map(image => (
              <img
                key={image.originalClass}
                src={image.original}
                alt={image.originalClass}
              />
            ))}
          </Carousel>
        )}
      </div>
    );
  }
}

CarouselContainer.propTypes = {
  unitDetailsId: PropTypes.string,
};

export default CarouselContainer;
