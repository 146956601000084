import * as React from 'react';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  Toolbar,
  Typography,
} from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GroupIcon from '@material-ui/icons/Group';
import RoomIcon from '@material-ui/icons/Room';
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { auth, firebase } from 'path-firebase';

import Form from '../Form.js';
import './styles.scss';

export const Nav = ({ classes, email }) => {
  const [value, setValue] = React.useState('');
  let location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();
  const logout = async () => oktaAuth.signOut();
  React.useEffect(() => {
    if (location.pathname.includes('/appointments')) {
      setValue('appointments');
    } else if (location.pathname.includes('/saved-searches')) {
      setValue('saved-searches');
    } else if (location.pathname.includes('/explore')) {
      setValue('explore');
    } else if (location.pathname.includes('/more')) {
      setValue('more');
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const provideFeedback = value => {
    let submission = value;
    let username = email.split('@')[0];
    const feedbackRef = firebase.database().ref('feedback');
    const item = {
      user: username,
      content: submission,
      created_at: new Date().toISOString(),
    };
    feedbackRef.push(item);
  };

  if (window.ReactNativeWebView) {
    return null;
  }

  return (
    <>
      <AppBar
        position="static"
        color="default"
        classes={{ root: 'hide-on-native-mobile app-bar-style' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters={true} classes={{ root: 'toolbar' }}>
            <div
              style={{ display: 'flex', paddingTop: '1.5rem' }}
              className="brand">
              <svg
                className="h-9 md:h-14 w-auto"
                height="36"
                fill="none"
                viewBox="0 0 58 70"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29 51.57c-12.509 0-22.685-10.154-22.685-22.636C6.315 16.454 16.491 6.302 29 6.302c12.508 0 22.685 10.153 22.685 22.633 0 12.482-10.177 22.637-22.685 22.637zm22.734-4.665A29.004 29.004 0 0058 28.935C58 12.98 44.992 0 29 0 13.01 0 0 12.98 0 28.934c0 6.494 2.109 12.63 6.097 17.75 1.408 1.813 2.887 3.309 4.535 4.934L29.053 70l16.472-16.445c2.295-2.268 4.277-4.228 6.209-6.65z"
                  fill="#ffffff"></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 41h19V31H20v10z"
                  fill="#ffffff"></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30 12l17 13.24V35L30 21.838V12z"
                  fill="#ffffff"></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30 12v10.025L13 35v-9.51L30 12z"
                  fill="#ffffff"></path>
              </svg>
              <Typography
                style={{ color: 'white', marginLeft: '0.5rem' }}
                variant="h5">
                LeaseUp
              </Typography>
            </div>
            <Button
              to="/explore"
              classes={{
                root:
                  value == 'explore'
                    ? 'app-bar-button active'
                    : 'app-bar-button',
              }}
              component={RouterLink}>
              Explore
            </Button>
            <Button
              to="/saved-searches"
              classes={{
                root:
                  value == 'saved-searches'
                    ? 'app-bar-button active'
                    : 'app-bar-button',
              }}
              component={RouterLink}>
              Saved Searches
            </Button>
            {/* <Button
              to="/appointments"
              classes={{
                root:
                  value == 'appointments'
                    ? 'app-bar-button active'
                    : 'app-bar-button',
              }}
              component={RouterLink}>
              My Appointments
            </Button> */}
            <Form provideFeedback={provideFeedback} />
            <Button
              to=""
              classes={{ root: 'app-bar-button-link sign-out' }}
              component={RouterLink}
              onClick={logout}>
              Sign Out
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        classes={{ root: 'bottom-nav' }}>
        <BottomNavigationAction
          label="Explore"
          value="explore"
          to="/explore"
          component={RouterLink}
          icon={<RoomIcon />}
        />
        <BottomNavigationAction
          label="Saved Searches"
          value="saved-searches"
          to="/saved-searches"
          component={RouterLink}
          icon={<GroupIcon />}
        />
        {/* <BottomNavigationAction
          label="Appointments"
          value="appointments"
          to="/appointments"
          component={RouterLink}
          icon={<EventAvailableIcon />}
        /> */}
        <BottomNavigationAction
          label="More"
          value="more"
          to="/more"
          component={RouterLink}
          icon={<MenuIcon />}
        />
      </BottomNavigation>
    </>
  );
};
