const bedData = {
  SRO: 'SRO',
  'Shared Housing': 'Shared Housing',
  Studio: 'Studio',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
};

const amenitiesData = {
  parking__c: 'Parking',
  x55_plus__c: '55+',
  wheelchair_accessible__c: 'Wheelchair Accessible',
  refrigerator__c: 'Refrigerator',
  dishwasher__c: 'Dishwasher',
  pets__c: 'Pets Allowed',
};

const evictionData = {
  no_evictions__c: 'Yes, my client has been evicted.',
};

const spaData = [
  { value: 'SPA 1', label: 'SPA 1' },
  { value: 'SPA 2', label: 'SPA 2' },
  { value: 'SPA 3', label: 'SPA 3' },
  { value: 'SPA 4', label: 'SPA 4' },
  { value: 'SPA 5', label: 'SPA 5' },
  { value: 'SPA 6', label: 'SPA 6' },
  { value: 'SPA 7', label: 'SPA 7' },
  { value: 'SPA 8', label: 'SPA 8' },
];

const savedQuery = {
  query: {
    bool: {
      must: [
        {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {
                      terms: {
                        'bedrooms__c.keyword': ['2'],
                      },
                    },
                  ],
                },
              },
              {
                match: {
                  spa__c: 'SPA 4',
                },
              },
              {
                range: {
                  account_rent__c: {
                    gte: 1500,
                    lte: 3000,
                    boost: 2,
                  },
                },
              },
              {
                bool: {
                  boost: 1,
                  minimum_should_match: 1,
                  should: [
                    {
                      term: {
                        no_evictions__c: false,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
};

// utils
const calcNum = num => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'currency',
    compactDisplay: 'short',
    currency: 'USD',
  }).format(num);
};

export { spaData, evictionData, savedQuery, amenitiesData, bedData, calcNum };
