import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

const styles = {
  closeButton: {
    color: 'white',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#1EAFED',
  },
  flex: {
    flex: 1,
  },
  dialog: {
    padding: '30px',
  },
  toolbar: {
    gap: '1rem',
  },
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

const modalRoot = document.getElementById('modal');

class FullDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.el = document.createElement('div');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      if (this.props.showModal) {
        this.setState({ open: true });
        modalRoot.appendChild(this.el);
      } else {
        modalRoot.removeChild(this.el);
      }
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.setShowModal(false);
  };

  render() {
    const { classes, renderHideUnitIcon } = this.props;
    return createPortal(
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        style={styles.dialog}>
        <AppBar className={classes.appBar}>
          <Toolbar variant="dense" classes={{ root: classes.toolbar }}>
            <Button
              onClick={this.handleClose}
              variant="outlined"
              className={classes.closeButton}>
              CLOSE
            </Button>
            {renderHideUnitIcon()}
          </Toolbar>
        </AppBar>
        {this.props.children}
      </Dialog>,
      this.el
    );
  }
}

FullDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullDialog);
