import * as React from 'react';

import { StateProvider } from '@appbaseio/reactivesearch';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useHistory } from 'react-router-dom';

import StepWrapper from '../StepWrapper';
import { Criteria } from 'Components/Criteria';
import {
  initialAnswers,
  reducer,
  LocationSelect,
  BedRoomSelect,
  RentalFeaturesSelect,
  RentalPriceSelect,
  ProgramInformationSelect,
  EMPTY_QUERY,
} from 'Components/UnitFilters';

import './styles.scss';
import { SavedSearchDetail } from '../SavedSearchDetail';

const getSteps = () => [
  'Add Criteria',
  'Location',
  'Bedrooms',
  'Rental Range',
  'Rental Features',
  'Program Information',
];

const Form = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [toggleResults, setToggleResults] = React.useState(false);
  const [criteriaAnswers, dispatch] = React.useReducer(reducer, initialAnswers);
  const steps = getSteps();
  const history = useHistory();

  React.useEffect(() => {
    if (activeStep === steps.length) {
      setToggleResults(true);
    } else if (activeStep === -1) {
      history.push('/saved-searches');
    }
  }, [activeStep, steps]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleLoading = cb => cb(false);

  return (
    <div className="matching-container">
      {toggleResults ? null : (
        <Stepper activeStep={activeStep} alternativeLabel className="stepper">
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <form className="step-container">
        <Paper
          className={`card-root ${
            activeStep === steps.length ? 'hide' : 'show'
          }`}>
          <StateProvider
            includeKeys={['value', 'query']}
            strict={false}
            render={({ searchState }) => {
              console.log({ searchState });
              return (
                <>
                  <StepWrapper
                    activeStep={activeStep}
                    currentStep={0}
                    steps={steps}>
                    <div className="criteria">
                      <Criteria
                        criteriaAnswers={criteriaAnswers}
                        dispatch={dispatch}
                        required={false}
                      />
                    </div>
                  </StepWrapper>
                  <StepWrapper
                    activeStep={activeStep}
                    currentStep={1}
                    steps={steps}>
                    <LocationSelect
                      savedQuery={EMPTY_QUERY}
                      searchState={searchState}
                    />
                  </StepWrapper>
                  <StepWrapper
                    activeStep={activeStep}
                    currentStep={2}
                    steps={steps}>
                    <BedRoomSelect
                      savedQuery={EMPTY_QUERY}
                      searchState={searchState}
                    />
                  </StepWrapper>
                  <StepWrapper
                    activeStep={activeStep}
                    currentStep={3}
                    steps={steps}>
                    <RentalPriceSelect
                      savedQuery={EMPTY_QUERY}
                      searchState={searchState}
                    />
                  </StepWrapper>
                  <StepWrapper
                    activeStep={activeStep}
                    currentStep={4}
                    steps={steps}>
                    <RentalFeaturesSelect
                      savedQuery={EMPTY_QUERY}
                      searchState={searchState}
                    />
                  </StepWrapper>
                  <StepWrapper
                    activeStep={activeStep}
                    currentStep={5}
                    steps={steps}>
                    <ProgramInformationSelect
                      savedQuery={EMPTY_QUERY}
                      searchState={searchState}
                    />
                  </StepWrapper>
                </>
              );
            }}
          />
          <div className="card-footer">
            <Button onClick={handleBack}>
              {!activeStep ? 'Cancel' : 'Back'}
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
            </Button>
          </div>
        </Paper>
      </form>
      {toggleResults ? (
        <SavedSearchDetail
          savedQueries={[EMPTY_QUERY]}
          criteriaAnswers={criteriaAnswers}
          handleLoading={handleLoading}
        />
      ) : null}
    </div>
  );
};

export default Form;
