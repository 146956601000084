import * as React from 'react';
import { TextField } from '@material-ui/core';

import Modal from 'Components/Matching/Modal';

const titles = [
  name => `Hide from ${name}'s matches?`,
  () => 'Please tell us why you are hiding this unit:',
  name => `Show this unit in ${name}'s matches?`,
];

const actions = [
  { action: 'Yes' },
  { action: 'Submit' },
  { action: 'Yes, show this unit' },
];

const HideUnitModal = ({
  hidden,
  name,
  open,
  setOpen,
  postHiddenUnit,
  unitId,
}) => {
  const [step, setStep] = React.useState(0);
  const [text, setText] = React.useState('');

  React.useEffect(() => {
    hidden && setStep(2);
  }, [hidden]);

  const handleStep = () => {
    setStep(1);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      return !hidden && setStep(0);
    }, 250);
  };

  const handleTextChange = ({ target: { value } }) => {
    setText(value);
  };

  const commits = [
    handleStep,
    () => postHiddenUnit({ unit_id: unitId, reason: text })(true),
    () => postHiddenUnit({ unit_id: unitId })(false),
  ];

  const isCommitEnabled = () => {
    if (step === 1) {
      return Boolean(text);
    }
    return step === 0 || step === 2;
  };

  return (
    <Modal
      title={titles[step](name)}
      open={open}
      setOpen={handleClose}
      btnOne="Cancel"
      btnTwo={actions[step].action}
      onCommit={commits[step]}
      commitEnabled={isCommitEnabled}>
      {step === 1 ? (
        <TextField
          fullWidth={true}
          multiline={true}
          minRows={'5'}
          placeholder="Reason for hiding this unit."
          onChange={handleTextChange}
          required
        />
      ) : null}
    </Modal>
  );
};

export default HideUnitModal;
